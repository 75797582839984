import { PUBLIC_ACTIONS } from "constants/actions/Public";

const oInitialState = {
  aBarrels: [],
  bError: false,
};

const PublicReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case PUBLIC_ACTIONS.GET_BARRELS:
      return { ...oState, aBarrels: oAction.data };
    case PUBLIC_ACTIONS.ERROR:
      return { ...oState, bError: true };
    case PUBLIC_ACTIONS.CLEAN:
      return { ...oState, bError: false, aBarrels: [] };
    default:
      return oState;
  }
}

export default PublicReducers;
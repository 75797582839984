import { CONSTANTS_LANGUAGES } from "constants/Languages";
import { SHARED_ACTIONS } from "constants/actions/Shared";

const oInitialState = {
    sLanguage: CONSTANTS_LANGUAGES.SPANISH,
    bLoading: false,
    sMessageTitle: null,
    sMessage: null,
    sMessageType: null
};

export const SharedReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case SHARED_ACTIONS.MESSAGE:
            return { ...oState, bLoading: false, sMessageTitle: oAction.title, sMessage: oAction.message, sMessageType: oAction.messageType };
        case SHARED_ACTIONS.CLEAN_MESSAGE:
            return { ...oState, sMessage: null, sMessageType: null };
        case SHARED_ACTIONS.SET_LANGUAGE:
            return { ...oState, sLanguage: oAction.data };
        case SHARED_ACTIONS.SHOW_LOADING:
            return { ...oState, bLoading: true };
        case SHARED_ACTIONS.DISMISS_LOADING:
            return { ...oState, bLoading: false };
        default:
            return oState;
    }
}

export const CONSTANTS_MESSAGE = {
    TYPE_ERROR: "error",
    TYPE_SUCCESS: "success",
    TYPE_INFO: "info",

    DEFAULT_MESSAGE_INFO_TITLE: "DEFAULT_MESSAGE_INFO_TITLE",
    DEFAULT_MESSAGE_ERROR_TITLE: "DEFAULT_MESSAGE_ERROR_TITLE",
    DEFAULT_MESSAGE_SUCCESS_TITLE: "DEFAULT_MESSAGE_SUCCESS_TITLE",

    DEFAULT_MESSAGE_ERROR: "DEFAULT_MESSAGE_ERROR",
};

import { LOG_ACTIONS } from "constants/actions/Log";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  aLogs: [],
  oPagination: null,
};

const LogReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case LOG_ACTIONS.GET:
      return { ...oState, aLogs: oAction.data.data, oPagination: oAction.data.pagination };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}

export default LogReducers;
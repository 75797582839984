import { SHARED_ACTIONS } from "constants/actions/Shared";
import { CONSTANTS_COLORS } from "constants/Colors";
import { DATES_FORMATS } from "constants/Dates";
import { STATUS_INVOICES, STATUS_INVOICES_CLASS, STATUS_INVOICES_CLASS_DISABLED, STATUS_INVOICES_LABELS } from "constants/Invoices";
import { CONSTANTS_MESSAGE } from "constants/Message";
import { STATUS_BARREL, STATUS_BARREL_CLASS, STATUS_BARREL_CLASS_DISABLED } from "constants/StatusBarrel";
import { STATUS_FERMENTER } from "constants/StatusFermenter";
import { setLanguageLocalStorage, setThemeLocalStorage } from "redux/services/StorageServices";

var oDayJs = require("dayjs");

/**
 * Función que realiza un dispatch global
 * 
 * @param {Action} sTypeAction Tipo de accion que se envia
 * @param {Data} oData Datos que se deseean enviar al redux
 * 
 * @author Leandro Curbelo
 */
export const actionDispatch = (sTypeAction, oData = null) => ({
  type: sTypeAction,
  data: oData
});
/**
 * Función que maneja los mensajes de error tomados desde el catch de los servicios
 * 
 * @param {string|object} oMessage Mensaje que se mostrara en pantalla
 * 
 * @author Leandro Curbelo
 */
export const actionManageMessage = (oMessage) => {
  return dispatch => {
    if (typeof oMessage === "string") {
      if (oMessage === "Network Error")
        return dispatch(actionMessage("NETWORK_ERROR"));
      return dispatch(actionMessage(oMessage));
    }
    if (oMessage?.data?.message)
      return dispatch(actionMessage(oMessage.data.message));
    dispatch(actionMessage());
    dispatch(actionDismissLoading());
  }
}
/**
 * Función que activa el mensaje Toast
 * 
 * @param {string} sMessage Mensaje que se mostrara en pantalla
 * 
 * @author Leandro Curbelo
 */
export const actionMessage = (sMessage = CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR, sMessageType = CONSTANTS_MESSAGE.TYPE_ERROR, sTitle) => ({
  type: SHARED_ACTIONS.MESSAGE,
  title: sTitle || (sMessageType === CONSTANTS_MESSAGE.TYPE_ERROR ? CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR_TITLE : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_SUCCESS_TITLE),
  message: sMessage,
  messageType: sMessageType
})
/**
 * Función que activa el mensaje Toast
 * 
 * @author Leandro Curbelo
 */
export const actionCleanMessage = () => {
  return dispatch => {
    dispatch(actionDispatch(SHARED_ACTIONS.CLEAN_MESSAGE));
  }
}
/**
 * Función que limpia el contador de loadings
 * 
 * @author Leandro Curbelo
 */
export const actionCleanLoading = () => {
  return dispatch => {
    dispatch(actionDispatch(SHARED_ACTIONS.CLEAN_LOADING));
  }
}
/**
 * Función que activa el loading
 * 
 * @author Leandro Curbelo
 */
export const actionShowLoading = () => {
  return dispatch => {
    dispatch(actionDispatch(SHARED_ACTIONS.SHOW_LOADING, null));
  }
};
/**
 * Función que desactiva el loading
 * 
 * @author Leandro Curbelo
 */
export const actionDismissLoading = () => {
  return dispatch => {
    dispatch(actionDispatch(SHARED_ACTIONS.DISMISS_LOADING, null));
  }
};
/**
 * Función que permite desde la vista enviar un mensaje
 * 
 * @param {string} sMessage Mensaje que se mostrara en pantalla
 * 
 * @author Leandro Curbelo
 */
export const actionSendMessage = (sMessage) => {
  return dispatch => {
    dispatch(actionMessage(sMessage));
  }
}

export const actionChangeLanguage = (sLanguage) => {
  return dispatch => {
    setLanguageLocalStorage(sLanguage);
    dispatch(actionDispatch(SHARED_ACTIONS.SET_LANGUAGE, sLanguage));
  }
}

export const actionChangeTheme = (sTheme) => {
  return dispatch => {
    setThemeLocalStorage(sTheme);
    dispatch(actionDispatch(SHARED_ACTIONS.SET_THEME, sTheme));
  }
}

/**
 * Función que le da un formato a todas las fechas del sistema, centraliza el uso de moment y el formato de las fechas
 * Casteo de fechas del tipo Date
 * 
 * @param {string} sDate Fecha en formato Date o string
 * @param {string} sFormat Formato de salida de la fecha recibida
 * 
 * @author Leandro Curbelo
 */
export const CastDate = (sDate = new Date(), sFormat = DATES_FORMATS.DEFAULT) => {
  return oDayJs(sDate).format(sFormat);
}

export const GetDayJsObject = (sDate = new Date()) => {
  return oDayJs(sDate);
}

export const CastFloat = (nFloat) => {
  return nFloat;
}
/**
 * Función que otorga la diferencia entre ambas fechas en la unidad recibida por parámetro
 * 
 * @param {string|Date} sSecondDate Fecha que se cree que es posterior a la segunda fecha pasada por parámetro
 * @param {string|Date} sFirstDate Fecha que se cree sucede anterior a la primera fecha pasada por parámetro
 * @param {string} sUnits Unidad en la que se retorna esta diferencia
 * 
 * @returns {number}
 */
export const GetDateDifference = (sSecondDate = new Date(), sFirstDate = new Date(), sUnits = DATES_FORMATS.CONSTANTS.UNITS.DAYS) => {
  const oFirstDate = oDayJs(sFirstDate);
  const oSecondDate = oDayJs(sSecondDate);
  return oSecondDate.diff(oFirstDate, sUnits);
}

export const GetStatusColorByDate = (sSecondDate = new Date(), sFirstDate = new Date()) => {
  const nDaysDifference = GetDateDifference(sSecondDate, sFirstDate);
  let sColor = CONSTANTS_COLORS.GRAY_COLOR;

  if (nDaysDifference < 7)
    sColor = CONSTANTS_COLORS.WARNING_COLOR;
  if (nDaysDifference < 2)
    sColor = CONSTANTS_COLORS.DANGER_COLOR;

  return sColor;
}

/**
 * Expresión regular para comprobar que la contraseña contenga todo lo especificado y ademas tenga 
 *  su largo minímo, a continuación se explica su composición
 *
 * ? [A-Za-z0-9@$!%*?&#] - Especifica los elementos que pueden contener la cadena (A-Z significa todas las mayúsculas, a-z todas las minusculas, 0-9 números y simbolos de forma individual)
 * ? {8,} - Largo de la cadena, su uso es siempre {1, 15} que significa de 1 a 15 caracteres, en nuestro caso significa al menos 8
 * ? (?=.*[a-z]) - Especifica que debe contener al menos una minuscula
 * ? (?=.*[A-Z]) - Especifica que debe contener al menos una mayuscula
 * ? (?=.*[0-9]) - Especifica que debe contener al menos un número
 * ? (?=.*[@$!%*?&#]) - Especifica que debe contener al menos un simbolo de los que se especifican dentro de los parentesís rectos
 * ? /^ - Apertura de la expresión regular
 * ? $/ - Cierre de la expresión regular
 */
export const PasswordValidator = (sPassword = "") => {
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#])[A-Za-z0-9@$!%*?&#]{8,}$/;
  return PASSWORD_REGEX.test(sPassword.toLowerCase());
}

export const EmailValidator = (sEmail = "") => {
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(sEmail.toLowerCase());
}

export const PhoneValidator = (sPhone = "") => {
  const PHONE_REGEX = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  return PHONE_REGEX.test(sPhone.toLowerCase());
}

export const GetLabelStatus = (nStatus) => {
  switch (nStatus) {
    case STATUS_BARREL.FULL_IN_FACTORY:
      return "FULL_IN_FACTORY";
    case STATUS_BARREL.FULL_IN_CLIENT:
      return "IN_CLIENT";
    case STATUS_BARREL.EMPTY_IN_FACTORY:
    case STATUS_BARREL.FINALIZED:
      return "EMPTY_IN_FACTORY";
    case STATUS_BARREL.REFUND:
      return "REFUND";
    case STATUS_BARREL.NEW:
    default:
      return "NEW";
  }
}

export const GetClassStatus = (nStatus, bActive = true) => {
  switch (nStatus) {
    case STATUS_BARREL.FULL_IN_FACTORY:
      return bActive ? STATUS_BARREL_CLASS.FULL_IN_FACTORY : STATUS_BARREL_CLASS_DISABLED.FULL_IN_FACTORY;
    case STATUS_BARREL.FULL_IN_CLIENT:
      return bActive ? STATUS_BARREL_CLASS.FULL_IN_CLIENT : STATUS_BARREL_CLASS_DISABLED.FULL_IN_CLIENT;
    case STATUS_BARREL.EMPTY_IN_FACTORY:
    case STATUS_BARREL.FINALIZED:
      return bActive ? STATUS_BARREL_CLASS.EMPTY_IN_FACTORY : STATUS_BARREL_CLASS_DISABLED.EMPTY_IN_FACTORY;
    case STATUS_BARREL.REFUND:
      return bActive ? STATUS_BARREL_CLASS.REFUND : STATUS_BARREL_CLASS_DISABLED.NEW;
    case STATUS_BARREL.NEW:
    default:
      return bActive ? STATUS_BARREL_CLASS.NEW : STATUS_BARREL_CLASS_DISABLED.NEW;
  }
}

export const GetBeerClientLabel = (oBarrel) => {
  if (oBarrel.client && oBarrel.beer)
    return `${oBarrel.beer} / ${oBarrel.client}`;
  return oBarrel.beer || oBarrel.client || "-";
};

export const GetBeerClientLabelByStatus = (oBarrel) => {
  switch (oBarrel.status) {
    case STATUS_BARREL.FULL_IN_FACTORY:
      return oBarrel.beer?.name || oBarrel.beer;
    case STATUS_BARREL.FULL_IN_CLIENT:
      return `${oBarrel.beer?.name || oBarrel.beer} / ${oBarrel.client?.name || oBarrel.client}`;
    case STATUS_BARREL.FINALIZED:
    case STATUS_BARREL.EMPTY_IN_FACTORY:
    case STATUS_BARREL.NEW:
    default:
      return "-";
  }
};

export const GetClassStatusFermenter = (nStatus, bActive = true) => {
  switch (nStatus) {
    case STATUS_FERMENTER.FULL:
      return bActive ? STATUS_BARREL_CLASS.FULL_IN_CLIENT : STATUS_BARREL_CLASS_DISABLED.FULL_IN_CLIENT;
    case STATUS_FERMENTER.EMPTY:
      return bActive ? STATUS_BARREL_CLASS.EMPTY_IN_FACTORY : STATUS_BARREL_CLASS_DISABLED.EMPTY_IN_FACTORY;
    case STATUS_FERMENTER.NEW:
    default:
      return bActive ? STATUS_BARREL_CLASS.NEW : STATUS_BARREL_CLASS_DISABLED.NEW;
  }
}

export const GetLabelStatusFermenter = (nStatus) => {
  return !nStatus || nStatus === STATUS_FERMENTER.NEW
    ? "NEW"
    : nStatus === STATUS_FERMENTER.FULL
      ? "FULL"
      : "EMPTY";
}

export const GetLabelTitle = (oBarrel) => {
  switch (oBarrel.status) {
    case STATUS_BARREL.FULL_IN_FACTORY:
      return oBarrel.beer || "FULL";
    case STATUS_BARREL.FULL_IN_CLIENT:
      return oBarrel.client || "IN_CLIENT";
    case STATUS_BARREL.EMPTY_IN_FACTORY:
    case STATUS_BARREL.NEW:
    default:
      return GetLabelStatus(oBarrel.status);
  }
}

export const GetLabelTitleFermenter = (oFermenter) => {
  switch (oFermenter.status) {
    case STATUS_FERMENTER.FULL:
      return oFermenter.beer || "FULL";
    case STATUS_FERMENTER.EMPTY:
      return "EMPTY";
    case STATUS_FERMENTER.NEW:
    default:
      return "NEW";
  }
}

export const GetNextStatus = (nStatus) => {
  //? Si el barril es nuevo o FULL_IN_CLIENT -> EMPTY_IN_FACTORY
  //? Si el barril esta en EMPTY_IN_FACTORY -> FULL_IN_FACTORY
  //? Si el barril esta en FULL_IN_FACTORY -> FULL_IN_CLIENT
  return (!nStatus || nStatus === STATUS_BARREL.FULL_IN_CLIENT)
    ? STATUS_BARREL.EMPTY_IN_FACTORY
    : nStatus === STATUS_BARREL.EMPTY_IN_FACTORY
      ? STATUS_BARREL.FULL_IN_FACTORY
      : STATUS_BARREL.FULL_IN_CLIENT;
}

export const GetMonthName = (nMonth) => {
  let sMonth = "S/D";
  switch (nMonth) {
    case 1:
      sMonth = "Enero"
      break;
    case 2:
      sMonth = "Febrero"
      break;
    case 3:
      sMonth = "Marzo"
      break;
    case 4:
      sMonth = "Abril"
      break;
    case 5:
      sMonth = "Mayo"
      break;
    case 6:
      sMonth = "Junio"
      break;
    case 7:
      sMonth = "Julio"
      break;
    case 8:
      sMonth = "Agosto"
      break;
    case 9:
      sMonth = "Septiembre"
      break;
    case 10:
      sMonth = "Octubre"
      break;
    case 11:
      sMonth = "Noviembre"
      break;
    case 12:
      sMonth = "Diciembre"
      break;
    default:
      sMonth = ""
      break;
  }
  return sMonth;
}

export const GenerateRandomColor = (sTransparency = "0.4") => {
  return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${sTransparency})`;
};

export const GetClassStatusInvoice = (nStatus, bActive = true) => {
  switch (nStatus) {
    case STATUS_INVOICES.NO_PAYED:
    default:
      return bActive ? STATUS_INVOICES_CLASS.NO_PAYED : STATUS_INVOICES_CLASS_DISABLED.NO_PAYED;
    case STATUS_INVOICES.PARTIALLY:
      return bActive ? STATUS_INVOICES_CLASS.PARTIALLY : STATUS_INVOICES_CLASS_DISABLED.PARTIALLY;
    case STATUS_INVOICES.PAYED:
      return bActive ? STATUS_INVOICES_CLASS.PAYED : STATUS_INVOICES_CLASS_DISABLED.PAYED;
  }
}

export const GetLabelStatusInvoice = (nStatus) => {
  switch (nStatus) {
    case STATUS_INVOICES.NO_PAYED:
    default:
      return STATUS_INVOICES_LABELS.NO_PAYED;
    case STATUS_INVOICES.PARTIALLY:
      return STATUS_INVOICES_LABELS.PARTIALLY;
    case STATUS_INVOICES.PAYED:
      return STATUS_INVOICES_LABELS.PAYED;
  }
}
import { CONSTANTS_SYSTEM } from "constants/System";
import oAxiosInstance from "redux/interceptors/Interceptors";

const URL_AUTH = `${CONSTANTS_SYSTEM.API_URL}auth/`;

export const LoginService = (sEmail, sPassword) => {
  return oAxiosInstance.post(URL_AUTH,
    {
      email: sEmail,
      password: sPassword
    });
}

export const UpdateProfile = (oData) => {
  return oAxiosInstance.put(`${URL_AUTH}`, { data: oData });
}

export const CheckCredentials = (sToken) => {
  return oAxiosInstance.post(`${URL_AUTH}check/credentials`, { data: sToken });
}

export const UploadCompanyImage = (oData) => {
  return oAxiosInstance.put(`${URL_AUTH}image`, { data: oData });
}

export const DeleteAccount = () => {
  return oAxiosInstance.delete(`${URL_AUTH}account`);
}

export const LogoutService = () => {
  return oAxiosInstance.get(`${URL_AUTH}logout`);
}

export const GetHomeService = () => {
  return oAxiosInstance.get(`${URL_AUTH}home`);
}

const URL_USERS = `${CONSTANTS_SYSTEM.API_URL}users/`;

export const Get = () => {
  return oAxiosInstance.get(URL_USERS);
}

export const Find = (nId) => {
  return oAxiosInstance.get(`${URL_USERS}${nId}`);
}

export const Update = (nId, oData) => {
  return oAxiosInstance.put(`${URL_USERS}${nId}`, { data: oData });
}

export const Delete = (nId) => {
  return oAxiosInstance.delete(`${URL_USERS}${nId}`);
}

export const Invite = (oData) => {
  return oAxiosInstance.post(`${URL_USERS}invite`, { data: oData });
}

import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { CONSTANTS_COLORS } from "constants/Colors";

const LoadingComponent = ({ open: bIsOpen = false }) => {

    if (!bIsOpen)
        return null;

    return <main className="w-screen h-screen top-0 left-0 flex fixed z-50 bg-gray-800 bg-opacity-60 items-center">
        <AiOutlineLoading3Quarters size={35} className='m-auto animate-spin' color={CONSTANTS_COLORS.COLORS.PRIMARY_COLOR} />
    </main>;
}

export default LoadingComponent;

import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  aUsers: null,
  oUser: null,
  oConfig: null,
  bSuccess: false,
  bGoBack: false,
};

const UserReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case USER_ACTIONS.GET:
      return { ...oState, aUsers: oAction.data };
    case USER_ACTIONS.FIND:
      return { ...oState, oUser: oAction.data };
    case USER_ACTIONS.GET_CONFIG:
      return { ...oState, oConfig: oAction.data };
    case USER_ACTIONS.GO_BACK:
      return { ...oState, bGoBack: true };
    case USER_ACTIONS.SUCCESS:
      return { ...oState, bSuccess: true };
    case USER_ACTIONS.CLEAN:
      return { ...oState, bSuccess: false, bGoBack: false };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}

export default UserReducers;
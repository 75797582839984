export const DATES_FORMATS = {
  EN: "YYYY/MM/DD",
  LATIN: "DD/MM/YYYY",
  TIMESTAMPS_EN: "YYYY/MM/DD H:mm:ss",
  TIMESTAMPS_LATIN: "DD/MM/YYYY H:mm:ss",

  DEFAULT: "DD/MM/YYYY",
  LOT: "YYYYMMDD",
  MYSQL: "YYYY/MM/DD",
  MYSQL_SERVER: "YYYY-MM-DD",
  MYSQL_SERVER_TIMESTAMPS_DEFAULT_24_HOURS: "YYYY-MM-DD H:mm:ss",
  TIMESTAMPS_DEFAULT: "YYYY/MM/DD H:mm:ss",
  TIMESTAMPS_DEFAULT_24_HOURS: "YYYY/MM/DD H:mm:ss",
  TIMESTAMPS_WITHOUT_SECONDS_24_HOURS: "YYYY/MM/DD H:mm",
  TIME_DEFAULT: "HH:mm",
  // Constantes de unidades para plugin y manejo de fechas
  CONSTANTS: {
    UNITS: {
      DAYS: "days",
      WEEKS: "week",
      MONTHS: "month",
      YEARS: "year",
    }
  }
}
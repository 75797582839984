import SharedContextProvider from "context/SharedContext";
import Layout from "pages/Layout";
import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { IconContext } from "react-icons";

import "react-toastify/dist/ReactToastify.css";

import oStore from "./redux/Store";

function App() {
  return <Provider store={oStore}>
    <IconContext.Provider value={{ className: "text-gray-800 dark:text-gray-100 text-md" }}>
      <SharedContextProvider>
        <Layout />
        <ToastContainer />
      </SharedContextProvider>
    </IconContext.Provider>
  </Provider>;
}

export default App;

export const FERMENTER_ACTIONS = {
  GET: "GET_FERMENTERS",
  CREATE: "CREATE_FERMENTERS",
  FIND: "FIND_FERMENTERS",
  GET_LOTS: "GET_LOTS_FERMENTERS",

  CHECK: "CHECK_FERMENTERS",
  GET_CONNECTORS: "GET_CONNECTORS_FERMENTERS",
  GET_LITERS: "GET_LITERS_FERMENTERS",

  SUCCESS: "SUCCESS_FERMENTERS",
  ERROR: "ERROR_FERMENTERS",
  CLEAN: "CLEAN_FERMENTERS",
  CLEAN_FERMENTER: "CLEAN_FERMENTER_FERMENTERS",
}
import React, { useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { SharedContext } from "context/SharedContext";

const Login = React.lazy(() => import(/*webpackChunkName: "Login" */ "pages/login/LoginPage"));
const Home = React.lazy(() => import(/*webpackChunkName: "Home" */ "pages/home/HomePage"));
const BarrelsPage = React.lazy(() => import(/*webpackChunkName: "BarrelsPage" */ "pages/barrels/BarrelsPage"));
const BarrelDetailsPage = React.lazy(() => import(/*webpackChunkName: "BarrelDetailsPage" */ "pages/barrels/BarrelDetailsPage"));
const FermentersPage = React.lazy(() => import(/*webpackChunkName: "FermentersPage" */ "pages/fermenters/FermentersPage"));
const FermentersDetailsPage = React.lazy(() => import(/*webpackChunkName: "FermentersDetailsPage" */ "pages/fermenters/FermentersDetailsPage"));
const FermentersLotsPage = React.lazy(() => import(/*webpackChunkName: "FermentersLotsPage" */ "pages/fermenters/FermentersLotsPage"));
const ClientsPage = React.lazy(() => import(/*webpackChunkName: "ClientsPage" */ "pages/clients/ClientsPage"));
const ClientsAddPage = React.lazy(() => import(/*webpackChunkName: "ClientsAddPage" */ "pages/clients/ClientsAddPage"));
const ClientsDetailsPage = React.lazy(() => import(/*webpackChunkName: "ClientsDetailsPage" */ "pages/clients/ClientsDetailsPage"));
const ClientInvoicesPage = React.lazy(() => import(/*webpackChunkName: "ClientInvoicesPage" */ "pages/clients/ClientInvoicesPage"));
const BeersPage = React.lazy(() => import(/*webpackChunkName: "BeersPage" */ "pages/beers/BeersPage"));
const BeersAddPage = React.lazy(() => import(/*webpackChunkName: "BeersAddPage" */ "pages/beers/BeersAddPage"));
const BeersDetailsPage = React.lazy(() => import(/*webpackChunkName: "BeersDetailsPage" */ "pages/beers/BeersDetailsPage"));
const ProfilePage = React.lazy(() => import(/*webpackChunkName: "ProfilePage" */ "pages/profile/ProfilePage"));
const UsersPage = React.lazy(() => import(/*webpackChunkName: "UsersPage" */ "pages/users/UsersPage"));
const UsersDetailsPage = React.lazy(() => import(/*webpackChunkName: "UsersDetailsPage" */ "pages/users/UsersDetailsPage"));
const InvoicesPage = React.lazy(() => import(/*webpackChunkName: "InvoicesPage" */ "pages/invoices/InvoicesPage"));
const InvoicesDetailsPage = React.lazy(() => import(/*webpackChunkName: "InvoicesDetailsPage" */ "pages/invoices/InvoicesDetailsPage"));
const LogsPage = React.lazy(() => import(/*webpackChunkName: "LogsPage" */ "pages/logs/LogsPage"));
const ScanPage = React.lazy(() => import(/*webpackChunkName: "ScanPage" */ "pages/public/clients/ScanPage"));

const aPublicRoutes = [
  { key: "login", path: "/", element: <Login /> },
  { key: "publicScan", path: "/public/scan", element: <ScanPage /> },
  { key: "notFound", path: "*", element: <Login /> },
];

const aPrivateRoutes = [
  { key: "home", path: "/", element: <Home /> },
  { key: "barrels", path: "/barrels", element: <BarrelsPage /> },
  { key: "barrelDetails", path: "/barrels/:code", element: <BarrelDetailsPage /> },
  { key: "clients", path: "/clients", element: <ClientsPage /> },
  { key: "clientsAdd", path: "/clients/add", element: <ClientsAddPage /> },
  { key: "clientsDetails", path: "/clients/:id", element: <ClientsDetailsPage /> },
  { key: "clientInvoices", path: "/clients/:id/invoices", element: <ClientInvoicesPage /> },
  { key: "beers", path: "/beers", element: <BeersPage /> },
  { key: "beersAdd", path: "/beers/add", element: <BeersAddPage /> },
  { key: "beersDetails", path: "/beers/:id", element: <BeersDetailsPage /> },
  { key: "fermenters", path: "/fermenters", element: <FermentersPage /> },
  { key: "fermenterDetails", path: "/fermenters/:code", element: <FermentersDetailsPage /> },
  { key: "fermenterLots", path: "/fermenters/:code/lots", element: <FermentersLotsPage /> },
  { key: "profile", path: "/profile", element: <ProfilePage /> },
  { key: "users", path: "/users", element: <UsersPage /> },
  { key: "usersDetails", path: "/users/:id", element: <UsersDetailsPage /> },
  { key: "invoices", path: "/invoices", element: <InvoicesPage /> },
  { key: "invoicesDetails", path: "/invoices/:id", element: <InvoicesDetailsPage /> },
  { key: "logs", path: "/logs", element: <LogsPage /> },
  { key: "notFound", path: "*", element: <Home /> },
];

export function RouteConfig() {

  const { IsAuthenticated } = useContext(SharedContext);
  const bIsAuthenticated = useMemo(() => IsAuthenticated(), [IsAuthenticated]);

  return <Routes>
    {
      bIsAuthenticated ?
        aPrivateRoutes.map(oRoute => <Route {...oRoute} />)
        :
        aPublicRoutes.map(oRoute => <Route {...oRoute} />)
    }
  </Routes>;
}


export const GetMessage = ({ SharedReducers: oState}) => {
    return {
        title: oState?.sMessageTitle,
        message: oState?.sMessage,
        type: oState?.sMessageType
    }
};

export const GetLanguage = ({ SharedReducers: oState}) => oState?.sLanguage;

export const GetLoading = ({ SharedReducers: oState}) => oState?.bLoading;

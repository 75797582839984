export const SHARED_ACTIONS = {
    MESSAGE: "MESSAGE",
    CLEAN_MESSAGE: "CLEAN_MESSAGE",
    SHOW_LOADING: "SHOW_LOADING",
    DISMISS_LOADING: "DISMISS_LOADING",
    CLEAN_LOADING: "CLEAN_LOADING",
    CLEAN: "CLEAN",

    SET_LANGUAGE: "SET_LANGUAGE",
    SET_THEME: "SET_THEME",

    CLEAN_SCANNER: "CLEAN_SCANNER",
};

exports.PORTUGUESE_TRANSLATE = {
  EMAIL: "O email",
  PASSWORD: "Senha",
  CONFIRM_PASSWORD: "Confirmar senha",
  COMPANY: "Companhia",
  COMPANY_NAME_REQUIRED: "Nome da empresa obrigatório",
  COMPANY_PHONE_REQUIRED: "Telefone da empresa obrigatório",
  COUNTRY: "País",
  REGISTER: "Registro",
  ENTER: "Entrar",
  ACCEPT: "Aceitar",
  INVITE: "Convidar",
  CANCEL: "Cancelar",
  DELETE: "Excluir",
  UPDATE: "Modificar",
  ATTENTION: "Atenção",
  CLIENTS: "Clientes",
  WELCOME: "Bem-vindo",
  BARREL: "Barril",
  BARRELS: "Barris",
  FERMENTERS: "Fermentadores",
  FERMENTER: "Fermentador",
  IN_FACTORY: "Na Fábrica",
  IN_CLIENT: "No cliente",
  NEW: "Novo",
  REFUND: "Devolvida",
  STATUS: "Estado",
  SCAN: "Varredura",
  LIST: "Lista",
  HELLO: "Olá",
  DATE: "Data",
  LOT: "Lote",
  LOTS: "Lotes",
  NOTHING_LOTS: "Sem lotes",
  LOT_DATE: "Data do lote",
  FULL: "Cheio",
  EMPTY: "Vazio",
  NOTHING_BARRELS_CLIENTS: "Neste momento todos os barris estão em casa 😌",
  NOTHING_BARRELS_FACTORY: "Não temos barris no depósito",
  NO_BARRELS: "Nenhum barril entrou",
  TOTAL: "Totais",
  PAYMENT: "Pagamento",
  ADD_PAYMENT: "Adicionar pagamento",
  YES: "Sim",
  NO: "Não",
  PARTIAL: "Parcial",
  NOTHING_FERMENTERS_FULL: "Sem fermentadores completos, hora de cozinhar 👨🏼‍🍳👩🏼‍🍳",
  NOTHING_FERMENTERS_EMPTY: "Todos os fermentadores estão cheios",
  NO_FERMENTERS: "Nenhum fermentador entrou",
  MY_PROFILE: "Meu perfil",
  CHANGE_PHOTO: "Mudar foto",
  NAME: "Nome",
  PHONE: "Telefone",
  ADDRESS: "Direção",
  SAVE: "Guardar",
  SUCCESS: "Sucesso",
  DELIVERED: "Entregue",
  RETRY: "Tente novamente",
  DATA_SCANNER: "Dados verificados",
  SCANNED_BARRELS: "Barris escaneados",
  SCANNED_FERMENTERS: "Fermentadores escaneados",
  EMPTY_SCANNED: "Nenhum dado digitalizado",
  EMPTY_SCANNED_MESSAGE: "Os dados digitalizados aparecerão aqui",
  EMPTY_LAST_INVOICES: "Nenhuma fatura recente",
  FULL_IN_FACTORY: "Cheio de fábrica",
  EMPTY_IN_FACTORY: "Vazio na fábrica",
  CONFIRMATION: "Confirmação",
  NO_DATA_SCANNED: "Nenhum dado é verificado ainda",
  CONTENT: "Conteúdo",
  CONNECTOR: "Conector",
  CONNECTORS: "Conectores",
  SELECT_GROUP_FEATURES: "Selecionar recursos do grupo",
  SELECT_BARREL_FEATURES: "Selecione os recursos do barril",
  SELECT: "Seleção",
  LITERS: "Litros",
  YEAST: "Levedura",
  YEAST_TYPE: "Tipo de levedura",
  YEAST_AMOUNT: "Quantidade de levedura",
  YEAST_GENERATION: "Geração de levedura",
  GENERATION: "Geração",
  IDENTIFIER: "Identificador",
  OPTIONAL: "Opcional",
  NO_LITERS_SELECTED: "Selecione o número de litros",
  NO_CONNECTOR_SELECTED: "Selecione um conector",
  NO_LOT_SELECTED: "Selecione um lote",
  NO_DATE_SELECTED: "Selecione uma data",
  NO_BEER_SELECTED: "Selecione um estilo de cerveja",
  NO_CLIENT_SELECTED: "Selecione um cliente",
  LOT_SELECTION: "Seleção de Lote",
  CLOSE_LOT: "Fechar lote",
  INFORMATION: "Informação",
  LOT_DETAILS: "Detalhes do lote",
  WHEN_FILLING: "Litros quando cheio",
  LITERS_REPORTED: "Litros informados",
  NO_ASSOCIATED_BARRELS: "Sem barris associados",
  NO_LOT_ASSOCIATED: "Nenhum lote associado",
  NO_BEER_ASSOCIATED: "Nenhuma cerveja associada",
  FINAL_PRICE: "Preço final",
  SELECT_DATE_OR_SCAN_FERMENTER: "Selecione uma data ou escaneie um fermentador",
  SELECT_DATE_AND_BEER: "Selecione a data e o tipo de cerveja",
  SCAN_FERMENTER: "Escanear fermentador",
  SCAN_DATA_TO_PROCESS: "Digitalize um dado para continuar",
  CLEAN: "Limpar",
  BEER: "Cerveja",
  CLIENT: "Cliente",
  RETURN_DATE: "Data de retorno",
  RETURN: "Retorno",
  NEXT_RETURN: "Próximo retorno",
  ESTIMATED_DATE_RETURN: "Data prevista para devolução",
  NOTHING_CLIENTS: "Nenhum cliente cadastrado",
  NOTHING_BEERS: "Sem cervejas registradas",
  NOTHING_FERMENTERS: "Nenhum fermentador registrado",
  NOTHING_BARRELS: "Nenhum barril registrado",
  NOTHING_INVOICES: "Nenhuma fatura registrada",
  EMPTY_ADDRESS: "Nenhum endereço atribuído",
  EMPTY_EMAIL: "Nenhum e-mail associado",
  EMPTY_PHONE: "Nenhum telefone associado",
  EMPTY_DESCRIPTION: "Nenhuma descrição associada",
  EMPTY_PRICE: "Sem preço associado",
  EMPTY_DATA_SHORT: "S/D",
  WITHOUT_YEAST: "Sem fermento associado",
  CLIENT_DETAILS: "Detalhe do cliente",
  CLIENT_DATA: "Dados do cliente",
  BEER_DETAILS: "Detalhe da cerveja",
  INVOICE_DETAILS: "Detalhe da fatura",
  USER_DETAILS: "Detalhes do usuário",
  SEARCH: "Pesquisar",
  FILTER: "Filtrar",
  CLOSE: "Fechar",
  NO_BARRELS_ASOCIATED: "Sem barris associados",
  NEW_CLIENT: "Novo cliente",
  NEW_BEER: "Nova cerveja",
  UPLOAD_PHOTO: "Carregar foto",
  UPLOAD_IMAGE: "Carregar imagem",
  PRICE: "Preço",
  PRICE_PER_LITER: "Preço por litro",
  DESCRIPTION: "Descrição",
  STYLE: "Estilo",
  INVOICE: "Fatura",
  INVOICES: "Faturas",
  INVOICES_OF: "Faturas de",
  INVOICES_IN_LAST_MONTHS: "Contas nos últimos meses",
  AMOUNT: "Quantidade",
  PAID_OUT: "Pago",
  PAY: "Pagar",
  PENDING_PAYMENT: "Pagamento pendente",
  PARTIALLY_PAID: "Parcialmente pago",
  PENDING_ACTION: "Ação pendente",
  WITHOUT_INVOICES: "Sem faturas",
  DEBT: "Dívida",
  WITHOUT_DEBT: "Sem dívida",
  ACCOUNT_STATUS: "Status da conta",
  VIEW_INVOICES: "Ver faturas",
  SING_UP_LABEL: "Não tens uma conta ainda? Cadastre-se",
  ACCEPT_TERM_AND_CONDITIONS: "Ao se inscrever, você concorda com nossos Termos de Serviço e Política de Privacidade.",
  PASSWORD_DO_NOT_MATCH: "As senhas não correspondem",
  PASSWORD_REQUIREMENTS: "Sua senha deve conter pelo menos 6 caracteres com pelo menos uma minúscula, uma maiúscula e um número",
  CHECK_MAILBOX: "Verifique sua caixa de correio e verifique sua conta para acessar o BrewControl",
  EMPTY_DATA_FILTERED: "Nenhum dado para esse filtro",
  VIEW_USERS: "Ver usuários",
  CONFIG: "Configuração",
  NOTHING_USERS: "Sem usuários",
  USER: "Usuário",
  USERS: "Usuários",
  LOGS: "Registros",
  NOTHING_LOGS: "Não há registros",
  LAST_CONNECTION: "Última conexão",
  INVITE_USER: "Convidar usuário",
  BARRELS_SELECTED: "Barris selecionados",
  IS_IN: "Está em",
  CONTACT_INFORMATION: "Informação de contato",
  CODE: "Código",
  FILLING_DATE: "Data de enchimento",
  BARREL_INFORMATION: "Informação do barril",
  SCAN_QR_CODE: "Escanear o código QR do barril",
  BARREL_NOT_FOUND: "Barril não encontrado",
  RE_SCAN: "Re-scan",

  SEND_LEAVE_BARRELS: "E-mail ao sair dos barris",
  SEND_LIFT_BARRELS: "E-mail ao levantar barris",
  SEND_REFUND_BARRELS: "E-mail ao devolver barris",
  TO_YOU: "Para sua empresa",
  AN_ERROR_OCURRED_UPDATING_CONFIG: "Ocorreu um erro ao atualizar a configuração",

  BARREL_FILLING: "Enchimento de barril",
  NUMBER_MALFORMED: "Número malformado",
  DRAG_HERE: "Arraste aqui",
  DROP_HERE: "Solte aqui",
  DROP_FILE_ERROR_MESSAGE: "Erro ao carregar o arquivo",
  ACTION_CANNOT_BE_UNDONE: "Esta ação não pode ser desfeita",

  CONFIRM_BARRELS: "¿Confirma a mudança de estado de {{count}} barril/s?",
  CONFIRM_NEW_BARRELS: "¿Você confirma a incorporação de {{count}} barris novos?",
  CONFIRM_CHANGE_BARRELS: "Tem certeza de que deseja alterar o status desses barris?",

  CONFIRM_FERMENTERS: "Confirmar mudança de estado do fermentador?",
  CONFIRM_NEW_FERMENTERS: "Confirmar a adição do novo fermentador?",
  CONFIRM_CHANGE_FERMENTERS: "Tem certeza de que deseja alterar o status deste fermentador?",

  CONFIRM_DELETE_CLIENT: "Tem certeza de que deseja remover o cliente?",

  CONFIRM_DELETE_BEER: "Tem certeza de que deseja remover a cerveja?",

  UNABLE_DELETE_CLIENT: "Não é possível excluir um cliente com barris associados",

  CONFIRM_DELETE_ACCOUNT: "Tem certeza de que deseja remover a sua conta?",

  CONFIRM_DELETE_INVOICE: "Tem certeza de que deseja excluir a fatura?",

  CONFIRM_DELETE_USER: "Tem certeza de que deseja excluir o usuário?",

  CONFIRM_DELETE_BARREL: "Tem certeza de que deseja excluir este barril?",
  CONFIRM_DELETE_BARRELS: "Tem certeza de que deseja excluir esses barris?",

  HOME: "Início",
  BEERS: "Cervejas",
  LOG_OUT: "Sair",
  BACK: "Voltar",

  EMAIL_REQUIRED: "Email obrigatório",
  PASSWORD_REQUIRED: "Senha requerida",
  NAME_REQUIRED: "Nome obrigatório",
  STYLE_REQUIRED: "Estilo obrigatório",
  ALL_FIELDS_ARE_REQUIRED: "Todos os campos são necessários",
  ACTION_REQUIRED: "Ação necessária",
  ADD_BARRELS: "Adicionar barris",
  BARREL_COUNT: "Quantidade de barris",
  BARREL_COUNT_REQUIRED: "Quantidade de barris requerida",
  BARREL_COUNT_INVALID: "Quantidade de barris inválida",
  ADD_FERMENTERS: "Adicionar fermentadores",
  FERMENTER_COUNT: "Quantidade de fermentadores",
  FERMENTER_COUNT_REQUIRED: "Quantidade de fermentadores requerida",
  FERMENTER_COUNT_INVALID: "Quantidade de fermentadores inválida",
  UPDATE_FERMENTER_DETAILS: "Atualizar dados do fermentador",
  LITERS_REQUIRED: "Litros necessários",
  LITERS_FORMAT_ERROR: "Litros não está correto",
  PRINT: "Imprimir",
  PRINT_QRS: "Imprimir QR codes",
  WANT_PRINT_CODES: "Deseja imprimir os QR codes?",
  PAYMENT_REQUIRED: "O pagamento é necessário",
  OPTIONS: "Opções",

  OPEN: "Aberto",
  FINALIZED: "Finalizado",

  EMAIL_FORMAT_ERROR: "Formato de email errado",

  NETWORK_ERROR: "Erro de rede",

  METHOD_NOT_IMPLEMENTED: "Método não implementado",

  DEFAULT_MESSAGE_ERROR: "Ocorreu um erro, tente novamente mais tarde",
  DEFAULT_MESSAGE_INFO_TITLE: "Informações",
  DEFAULT_MESSAGE_ERROR_TITLE: "Temos um problema ❌",
  DEFAULT_MESSAGE_SUCCESS_TITLE: "Sucesso ✅",

  THEME: "Tema",
  LIGHT: "Claro",
  DARK: "Escuro",
  SYSTEM: "Sistema",

  LANGUAGE: "Língua",
  PT: "Português",
  ES: "Espanhol",
  EN: "Inglês",
  LOADING: "Carregando",
};

import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  oUser: null,

  oHome: null,
};

export const SessionReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case USER_ACTIONS.LOGIN_SUCCESS:
      return { ...oState, oUser: oAction.data };
    case USER_ACTIONS.GET_HOME:
      return { ...oState, oHome: oAction.data };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}
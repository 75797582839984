import { INVOICE_ACTIONS } from "constants/actions/Invoice";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  aInvoices: null,
  oInvoice: null,
  oPagination: null,

  bSuccess: false,
  bGoBack: false,
};

const InvoiceReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case INVOICE_ACTIONS.GET:
      return { ...oState, aInvoices: oAction.data.data, oPagination: oAction.data.pagination };
    case INVOICE_ACTIONS.FIND:
      return { ...oState, oInvoice: oAction.data };
    case INVOICE_ACTIONS.SUCCESS:
      return { ...oState, bSuccess: true };
    case INVOICE_ACTIONS.GO_BACK:
      return { ...oState, bGoBack: true };
    case INVOICE_ACTIONS.CLEAN:
      return { ...oState, oInvoice: null, bSuccess: false, bGoBack: false };
    case INVOICE_ACTIONS.CLEAN_SUCCESS:
      return { ...oState, bSuccess: false };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}

export default InvoiceReducers;
export const BARREL_ACTIONS = {
    GET: "GET_BARRELS",
    FIND: "FIND_BARRELS",
    CREATE: "CREATE_BARRELS",
    CLEAN_BARREL: "CLEAN_BARREL_BARRELS",

    GET_CONNECTORS: "GET_CONNECTORS_BARRELS",
    GET_LITERS: "GET_LITERS_BARRELS",
    GO_BACK: "GO_BACK_BARRELS",

    SUCCESS: "SUCCESS_BARRELS",
    ERROR: "ERROR_BARRELS",
    CLEAN: "CLEAN_BARRELS",
}
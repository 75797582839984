export const USER_ACTIONS = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS_USER",
  LOGOUT: "LOGOUT_USER",
  GET_HOME: "GET_HOME_USER",

  GET: "GET_USER",
  FIND: "FIND_USER",
  GO_BACK: "GO_BACK_USER",
  SUCCESS: "SUCCESS_USER",
  CLEAN: "CLEAN_USER",
}

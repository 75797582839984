import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronsLeft, FiHome, FiLogOut, FiMenu, FiUser, FiUsers } from "react-icons/fi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiBeer } from "react-icons/bi";

import MenuItemComponent from "./MenuItemComponent";
import ImageComponent from "components/shared/ImageComponent";
import TextComponent from "components/shared/TextComponent";

import { actionLogout } from "redux/actions/UserActions";
import { GetUser } from "redux/selectors/SessionSelector";
import { CONSTANTS_MEASURES } from "constants/Measures";
import { CONSTANTS_COLORS } from "constants/Colors";

import Logo from "assets/images/png/logo.png";
import oFermenterIcon from "assets/images/png/fermenter-icon.png";
import oBarrelIcon from "assets/images/png/barrel-icon.png";

const MenuComponent = ({ open: bIsOpen, onChange }) => {

    const oDispatch = useDispatch();
    const oUser = useSelector(GetUser);

    const onLogout = () => {
        oDispatch(actionLogout());
    }

    return <div className={"fixed h-screen transition-all duration-300 shadow-lg z-40 " + (bIsOpen ? "w-64" : "w-14")}>
        <div className="h-full overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <div className={`${bIsOpen ? "grid grid-cols-6" : ""} items-center mb-8 text-center bg-primary-500 dark:bg-primary-800 px-4 py-4`}>
                <span onClick={() => onChange(!bIsOpen)} className={`${bIsOpen ? "flex" : "hidden"} items-center pl-2.5 col-span-5 transition-all cursor-pointer`}>
                    <div className="h-10 w-10 mr-3 bg-white rounded-lg" >
                        <ImageComponent path={Logo} />
                    </div>
                    <span className="self-center text-lg font-semibold whitespace-nowrap text-white">Brew Control</span>
                </span>
                {
                    bIsOpen ?
                        <div className="h-10 w-10 text-center cursor-pointer">
                            <FiChevronsLeft size={CONSTANTS_MEASURES.FONT_SIZE_EXTRA_LARGE} className="text-white ml-auto h-full" onClick={() => onChange(!bIsOpen)} />
                        </div>
                        :
                        <div className="h-10 text-center cursor-pointer">
                            <FiMenu size={CONSTANTS_MEASURES.FONT_SIZE_EXTRA_LARGE} className="m-auto h-full text-white" onClick={() => onChange(!bIsOpen)} />
                        </div>
                }
            </div>
            <ul className={"space-y-2 relative " + (bIsOpen ? "px-4" : "px-1")}>
                <MenuItemComponent label="HOME" path="/" onlyIcon={!bIsOpen}>
                    <FiHome size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
                <MenuItemComponent label="FERMENTERS" path="/fermenters" onlyIcon={!bIsOpen}>
                    <img src={oFermenterIcon} className="h-7" alt="Fermenter icon" />
                </MenuItemComponent>
                <MenuItemComponent label="BARRELS" path="/barrels" onlyIcon={!bIsOpen}>
                    <img src={oBarrelIcon} className="h-7" alt="Barrel icon" />
                </MenuItemComponent>
                <MenuItemComponent label="CLIENTS" path="/clients" onlyIcon={!bIsOpen}>
                    <FiUsers size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
                <MenuItemComponent label="BEERS" path="/beers" onlyIcon={!bIsOpen}>
                    <BiBeer size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
                <MenuItemComponent label="INVOICES" path="/invoices" onlyIcon={!bIsOpen}>
                    <FaRegMoneyBillAlt size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
                <MenuItemComponent label="MY_PROFILE" path="/profile" onlyIcon={!bIsOpen}>
                    <FiUser size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
                <MenuItemComponent label="LOG_OUT" className="mt-8" onlyIcon={!bIsOpen} onClick={onLogout}>
                    <FiLogOut size={CONSTANTS_MEASURES.FONT_SIZE_LARGE} color={CONSTANTS_COLORS.COLORS.INHERIT} />
                </MenuItemComponent>
            </ul>
            <div className={"absolute inset-x-0 bottom-0 w-full " + (bIsOpen ? "border-t-2 px-4 py-4" : "px-1 py-4")}>
                <div className="grid grid-cols-12">
                    <div className={(bIsOpen ? "col-span-3" : "col-span-12")}>
                        <div className="h-12 w-12">
                            <ImageComponent path={oUser.company?.image || Logo} className="h-12 rounded-full ml-auto object-cover overflow-hidden"></ImageComponent>
                        </div>
                    </div>
                    <div className={"col-span-7 align-middle " + (!bIsOpen && "hidden")}>
                        <TextComponent className="whitespace-pre">
                            {oUser.name}
                        </TextComponent>
                        <TextComponent color={CONSTANTS_COLORS.CLASS.GRAY_LIGHT} size={CONSTANTS_MEASURES.CLASS.FONT_SIZE_EXTRA_SMALL} className="whitespace-pre">
                            {oUser.email}
                        </TextComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default MenuComponent;

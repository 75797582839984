export const STATUS_INVOICES = {
  PAYED: 1,
  PARTIALLY: 2,
  NO_PAYED: 3,
};

export const STATUS_INVOICES_LABELS = {
  PAYED: "PAID_OUT",
  PARTIALLY: "PARTIALLY_PAID",
  NO_PAYED: "PENDING_PAYMENT",
};

export const STATUS_INVOICES_CLASS = {
  PAYED: "bg-green-500 dark:bg-green-800 text-white dark:text-gray-300 w-min px-1 py-1 md:px-3 rounded-full text-xs",
  PARTIALLY: "bg-yellow-300 dark:bg-yellow-800 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
  NO_PAYED: "bg-red-500 dark:bg-red-800 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
};

export const STATUS_INVOICES_CLASS_DISABLED = {
  PAYED: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min px-1 py-1 md:px-3 rounded-full text-xs",
  PARTIALLY: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
  NO_PAYED: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
};

import { CONFIG_ACTIONS } from "constants/actions/Config";

const oInitialState = {
  oConfig: null,
};

const ConfigReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case CONFIG_ACTIONS.GET:
      return { ...oState, oConfig: oAction.data };
    default:
      return oState;
  }
}

export default ConfigReducers;

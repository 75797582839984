import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { SharedContext } from "context/SharedContext";

const MenuItemComponent = ({
    onClick,
    children,
    path: sPath = null,
    label: sLabel = null,
    className: sClassName = "",
    onlyIcon: bOnlyIcon = false,
 }) => {

    const { GetTranslation } = useContext(SharedContext);

    const getClass = ({ isActive: bIsActive }) => {
        let sClass = [
            "flex items-center p-2 text-base font-normal rounded-lg",
            "text-gray-500",
            "dark:text-white",
            "dark:hover:bg-primary-dark",
            "hover:bg-primary-dark",
            "dark:hover:text-primary-500",
            "hover:text-primary-500",
        ];
        sClass.push(sClassName);
        if (sPath)
            sClass.push(bIsActive ? "bg-primary-dark dark:text-primary-500 text-primary-500" : "");
        return sClass.join(" ");
    }

    return <li>
        <NavLink
            to={sPath || ""}
            className={getClass}
            onClick={() => onClick && onClick()}
        >
            {({ isActive: bIsActive }) => (
                <>
                    <div className={((bIsActive && sPath) ? "text-primary-500 " : "text-gray-400 ") + (bOnlyIcon ? "m-auto" : "")} >
                        {children}
                    </div>
                    {
                        !bOnlyIcon &&
                        <span className={"text-sm whitespace-pre " + (children ? "ml-3" : "ml-9")}>{GetTranslation(sLabel)}</span>
                    }
                </>
            )}
        </NavLink>
    </li>;
}

export default MenuItemComponent;

exports.SPANISH_TRANSLATE = {
  EMAIL: "Email",
  PASSWORD: "Contraseña",
  CONFIRM_PASSWORD: "Confirmar contraseña",
  COMPANY: "Empresa",
  COMPANY_NAME_REQUIRED: "Nombre de empresa requerido",
  COMPANY_PHONE_REQUIRED: "Teléfono de empresa requerido",
  COUNTRY: "País",
  REGISTER: "Registro",
  ENTER: "Ingresar",
  ACCEPT: "Aceptar",
  INVITE: "Invitar",
  CANCEL: "Cancelar",
  DELETE: "Eliminar",
  UPDATE: "Modificar",
  ATTENTION: "Atención",
  CLIENTS: "Clientes",
  WELCOME: "Bienvenido",
  BARREL: "Barril",
  BARRELS: "Barriles",
  FERMENTERS: "Fermentadores",
  FERMENTER: "Fermentador",
  IN_FACTORY: "En Fábrica",
  IN_CLIENT: "En Cliente",
  NEW: "Nuevo",
  REFUND: "Devuelto",
  STATUS: "Estado",
  SCAN: "Escanear",
  LIST: "Listado",
  HELLO: "Hola",
  DATE: "Fecha",
  LOT: "Lote",
  LOTS: "Lotes",
  NOTHING_LOTS: "Sin lotes",
  LOT_DATE: "Fecha de Lote",
  FULL: "Lleno",
  EMPTY: "Vacío",
  NOTHING_BARRELS_CLIENTS: "De momento todos los barriles están en casa 😌",
  NOTHING_BARRELS_FACTORY: "No contamos con barriles en el depósito",
  NO_BARRELS: "Sin barriles ingresados",
  TOTAL: "Total",
  PAYMENT: "Pago",
  ADD_PAYMENT: "Agregar pago",
  YES: "Sí",
  NO: "No",
  PARTIAL: "Parcial",
  NOTHING_FERMENTERS_FULL: "Sin fermentadores llenos, hora de cocinar 👨🏼‍🍳👩🏼‍🍳",
  NOTHING_FERMENTERS_EMPTY: "Todos los fermentadores están llenos",
  NO_FERMENTERS: "Sin fermentadores ingresados",
  MY_PROFILE: "Mi Perfil",
  CHANGE_PHOTO: "Cambiar foto",
  NAME: "Nombre",
  PHONE: "Teléfono",
  ADDRESS: "Dirección",
  SAVE: "Guardar",
  SUCCESS: "Éxito",
  DELIVERED: "Entregado",
  RETRY: "Reintentar",
  DATA_SCANNER: "Datos escaneados",
  SCANNED_BARRELS: "Barriles escaneados",
  SCANNED_FERMENTERS: "Fermentadores escaneados",
  EMPTY_SCANNED: "Sin datos escaneados",
  EMPTY_SCANNED_MESSAGE: "Aquí aparecerán los datos escaneados",
  EMPTY_LAST_INVOICES: "Sin facturas recientes",
  FULL_IN_FACTORY: "Lleno en fábrica",
  EMPTY_IN_FACTORY: "Vacío en fábrica",
  CONFIRMATION: "Confirmación",
  NO_DATA_SCANNED: "Aún no se escanea ningún dato",
  CONTENT: "Contenido",
  CONNECTOR: "Conector",
  CONNECTORS: "Conectores",
  SELECT_GROUP_FEATURES: "Seleccione características del grupo",
  SELECT_BARREL_FEATURES: "Seleccione las características del barril",
  SELECT: "Seleccionar",
  LITERS: "Litros",
  YEAST: "Levadura",
  YEAST_TYPE: "Tipo de levadura",
  YEAST_AMOUNT: "Cantidad de levadura",
  YEAST_GENERATION: "Generación de levadura",
  GENERATION: "Generación",
  IDENTIFIER: "Identificador",
  OPTIONAL: "Opcional",
  NO_LITERS_SELECTED: "Seleccione cantidad de litros",
  NO_CONNECTOR_SELECTED: "Seleccione un connector",
  NO_LOT_SELECTED: "Seleccione un lote",
  NO_DATE_SELECTED: "Seleccione una fecha",
  NO_BEER_SELECTED: "Seleccione un estilo de cerveza",
  NO_CLIENT_SELECTED: "Seleccione un cliente",
  LOT_SELECTION: "Selección de Lote",
  CLOSE_LOT: "Cerrar lote",
  INFORMATION: "Información",
  LOT_DETAILS: "Detalles del lote",
  WHEN_FILLING: "Litros al llenarse",
  LITERS_REPORTED: "Litros reportados",
  NO_ASSOCIATED_BARRELS: "Sin barriles asociados",
  NO_LOT_ASSOCIATED: "Sin lote asociado",
  NO_BEER_ASSOCIATED: "Sin cerveza asociada",
  FINAL_PRICE: "Precio final",
  SELECT_DATE_OR_SCAN_FERMENTER: "Seleccione una fecha o escanee un fermentador",
  SELECT_DATE_AND_BEER: "Seleccione la fecha y el tipo de cerveza",
  SCAN_FERMENTER: "Escanear fermentador",
  SCAN_DATA_TO_PROCESS: "Escanee un dato para continuar",
  CLEAN: "Limpiar",
  BEER: "Cerveza",
  CLIENT: "Cliente",
  RETURN_DATE: "Fecha de devolución",
  RETURN: "Devolución",
  NEXT_RETURN: "Próxima devolución",
  ESTIMATED_DATE_RETURN: "Fecha estimada para la devolución",
  NOTHING_CLIENTS: "Sin clientes registrados",
  NOTHING_BEERS: "Sin cervezas registradas",
  NOTHING_FERMENTERS: "Sin fermentadores registrados",
  NOTHING_BARRELS: "Sin barriles registrados",
  NOTHING_INVOICES: "Sin facturas registradas",
  EMPTY_ADDRESS: "Sin dirección asignada",
  EMPTY_EMAIL: "Sin correo electrónico asociado",
  EMPTY_PHONE: "Sin teléfono asociado",
  EMPTY_DESCRIPTION: "Sin descripción asociada",
  EMPTY_PRICE: "Sin precio asociado",
  EMPTY_DATA_SHORT: "S/D",
  WITHOUT_YEAST: "Sin levadura asociada",
  CLIENT_DETAILS: "Detalle de cliente",
  CLIENT_DATA: "Datos del cliente",
  BEER_DETAILS: "Detalle de la cerveza",
  INVOICE_DETAILS: "Detalle de la factura",
  USER_DETAILS: "Detalles de usuario",
  SEARCH: "Buscar",
  FILTER: "Filtrar",
  CLOSE: "Cerrar",
  NO_BARRELS_ASOCIATED: "Sin barriles asociados",
  NEW_CLIENT: "Nuevo cliente",
  NEW_BEER: "Nueva cerveza",
  UPLOAD_PHOTO: "Subir foto",
  UPLOAD_IMAGE: "Subir imagen",
  PRICE: "Precio",
  PRICE_PER_LITER: "Precio por litro",
  DESCRIPTION: "Descripción",
  STYLE: "Estilo",
  INVOICE: "Factura",
  INVOICES: "Facturas",
  INVOICES_OF: "Facturas de",
  INVOICES_IN_LAST_MONTHS: "Facturas en los últimos meses",
  AMOUNT: "Monto",
  PAID_OUT: "Pagado",
  PAY: "Pagar",
  PENDING_PAYMENT: "Pago pendiente",
  PARTIALLY_PAID: "Pagado parcialmente",
  PENDING_ACTION: "Acción pendiente",
  WITHOUT_INVOICES: "Sin facturas",
  DEBT: "Deuda",
  WITHOUT_DEBT: "Sin deuda",
  ACCOUNT_STATUS: "Estado de la cuenta",
  VIEW_INVOICES: "Ver facturas",
  SING_UP_LABEL: "¿Aún no tienes una cuenta? Regístrate",
  ACCEPT_TERM_AND_CONDITIONS: "Al registrarte, aceptas nuestros Términos de servicio y Política de privacidad.",
  PASSWORD_DO_NOT_MATCH: "Las contraseñas no coinciden",
  PASSWORD_REQUIREMENTS: "Su contraseña debe contener al menos 6 caracteres con al menos una minúscula, una mayúscula y un número",
  CHECK_MAILBOX: "Por favor revise su casilla de correo y verifique su cuenta para acceder a BrewControl",
  EMPTY_DATA_FILTERED: "Sin datos para ese filtro",
  VIEW_USERS: "Ver usuarios",
  CONFIG: "Configuración",
  NOTHING_USERS: "Sin usuarios",
  USER: "Usuario",
  USERS: "Usuarios",
  LOGS: "Registros",
  NOTHING_LOGS: "Sin registros",
  LAST_CONNECTION: "Última conexión",
  INVITE_USER: "Invitar usuario",
  BARRELS_SELECTED: "Barriles seleccionados",
  IS_IN: "Se encuentra en",
  CONTACT_INFORMATION: "Información de contacto",
  CODE: "Código",
  FILLING_DATE: "Fecha de llenado",
  BARREL_INFORMATION: "Información del barril",
  SCAN_QR_CODE: "Escanea el código QR del barril",
  BARREL_NOT_FOUND: "Barril no encontrado",
  RE_SCAN: "Volver a escanear",

  SEND_LEAVE_BARRELS: "Email al dejar barriles",
  SEND_LIFT_BARRELS: "Email al levantar barriles",
  SEND_REFUND_BARRELS: "Email al devolver barriles",
  TO_YOU: "A tu empresa",
  AN_ERROR_OCURRED_UPDATING_CONFIG: "Ocurrió un error actualizando la configuración",

  BARREL_FILLING: "Llenado de barril",
  NUMBER_MALFORMED: "Número malformado",
  DRAG_HERE: "Arrastre aquí el archivo",
  DROP_HERE: "Suelte aquí el archivo",
  DROP_FILE_ERROR_MESSAGE: "Error al subir el archivo",
  ACTION_CANNOT_BE_UNDONE: "Esta acción no se puede deshacer",

  CONFIRM_BARRELS: "¿Confirma el cambio de estado de {{count}} barril/es?",
  CONFIRM_NEW_BARRELS: "¿Confirma la incorporación de {{count}} barril/es nuevo/s?",
  CONFIRM_CHANGE_BARRELS: "¿Seguro que desea cambiar el estado de estos barriles?",

  CONFIRM_FERMENTERS: "¿Confirma el cambio de estado del fermentador?",
  CONFIRM_NEW_FERMENTERS: "¿Confirma la incorporación del nuevo fermentador?",
  CONFIRM_CHANGE_FERMENTERS: "¿Seguro que desea cambiar el estado de este fermentador?",

  CONFIRM_DELETE_CLIENT: "¿Está seguro que desea eliminar el cliente?",

  CONFIRM_DELETE_BEER: "¿Estás segura de que quieres eliminar la cerveza?",

  UNABLE_DELETE_CLIENT: "No se puede eliminar un cliente con barriles asociados",

  CONFIRM_DELETE_ACCOUNT: "¿Está seguro que desea eliminar su cuenta?",

  CONFIRM_DELETE_INVOICE: "¿Está seguro que desea eliminar la factura?",

  CONFIRM_DELETE_USER: "¿Estás seguro de que quieres eliminar el usuario?",

  CONFIRM_DELETE_BARREL: "¿Estás seguro de que quieres eliminar este barril?",
  CONFIRM_DELETE_BARRELS: "¿Estás seguro de que quieres eliminar estos barriles?",

  HOME: "Inicio",
  BEERS: "Cervezas",
  LOG_OUT: "Cerrar sesión",
  BACK: "Atrás",

  EMAIL_REQUIRED: "Email requerido",
  PASSWORD_REQUIRED: "Contraseña requerida",
  NAME_REQUIRED: "Nombre requerido",
  STYLE_REQUIRED: "Estilo requerido",
  ALL_FIELDS_ARE_REQUIRED: "Todos los campos son requeridos",
  ACTION_REQUIRED: "Acción requerida",
  ADD_BARRELS: "Agregar barriles",
  BARREL_COUNT: "Cantidad de barriles",
  BARREL_COUNT_REQUIRED: "Se requiere la cantidad de barriles",
  BARREL_COUNT_INVALID: "La cantidad de barriles debe ser un número",
  ADD_FERMENTERS: "Agregar fermentadores",
  FERMENTER_COUNT: "Cantidad de fermentadores",
  FERMENTER_COUNT_REQUIRED: "Se requiere la cantidad de fermentadores",
  FERMENTER_COUNT_INVALID: "La cantidad de fermentadores debe ser un número",
  UPDATE_FERMENTER_DETAILS: "Actualizar los datos del fermentador",
  LITERS_REQUIRED: "Litros requeridos",
  LITERS_FORMAT_ERROR: "Litros no es correcto",
  PRINT: "Imprimir",
  PRINT_QRS: "Imprimir QR's",
  WANT_PRINT_CODES: "¿Desea imprimir los códigos?",
  PAYMENT_REQUIRED: "El pago es requerido",
  OPTIONS: "Opciones",

  OPEN: "Abierto",
  FINALIZED: "Finalizado",

  EMAIL_FORMAT_ERROR: "Formato de email incorrecto",

  NETWORK_ERROR: "Sin conexión a internet",

  METHOD_NOT_IMPLEMENTED: "Método no implementado",

  DEFAULT_MESSAGE_ERROR: "Ha ocurrido un error, por favor intentelo de nuevo más tarde",
  DEFAULT_MESSAGE_INFO_TITLE: "Información",
  DEFAULT_MESSAGE_ERROR_TITLE: "Tenemos un problema ❌",
  DEFAULT_MESSAGE_SUCCESS_TITLE: "Éxito ✅",

  THEME: "Tema",
  LIGHT: "Claro",
  DARK: "Obscuro",
  SYSTEM: "Sistema",

  LANGUAGE: "Idioma",
  PT: "Portugués",
  ES: "Español",
  EN: "Inglés",
  LOADING: "Cargando",
};

import { CONSTANTS_LANGUAGES } from "constants/Languages";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetLanguage } from "redux/selectors/SharedSelector";
import { getLanguageLocalStorage, getThemeLocalStorage, setLanguageLocalStorage, setThemeLocalStorage } from "redux/services/StorageServices";
import { actionChangeLanguage, actionChangeTheme } from "redux/actions/SharedActions";

import { ENGLISH_TRANSLATE } from "assets/languages/en";
import { SPANISH_TRANSLATE } from "assets/languages/es";
import { PORTUGUESE_TRANSLATE } from "assets/languages/pt";
import { GetUser } from "redux/selectors/SessionSelector";
import { CONSTANTS_THEMES } from "constants/Themes";
import { DATES_FORMATS } from "constants/Dates";

export const SharedContext = React.createContext({});

export default function SharedContextProvider({ children }) {
  const oDispatch = useDispatch();

  const sLanguage = useSelector(GetLanguage);
  const oUser = useSelector(GetUser);

  const [bDarkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    const oThemeStoragePromise = getThemeLocalStorage();
    oThemeStoragePromise.then(oSuccess => ChangeTheme(oSuccess || CONSTANTS_THEMES.LIGHT));
    const oLanguageStoragePromise = getLanguageLocalStorage();
    oLanguageStoragePromise.then(oSuccess => {
      if (!oSuccess)
        setLanguageLocalStorage(CONSTANTS_LANGUAGES.SPANISH);
      oDispatch(actionChangeLanguage(oSuccess || CONSTANTS_LANGUAGES.SPANISH));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const IsAuthenticated = () => {
    return !!oUser;
  }

  const GetLanguageStorage = async () => {
    return getLanguageLocalStorage();
  }

  const GetThemeStorage = async () => {
    return getThemeLocalStorage();
  }

  const ChangeTheme = (sTheme) => {
    switch (sTheme) {
      case CONSTANTS_THEMES.DARK:
        setDarkTheme(true);
        break;
      case CONSTANTS_THEMES.LIGHT:
      default:
        setDarkTheme(false);
        break;
    }
    oDispatch(actionChangeTheme(sTheme || CONSTANTS_THEMES.LIGHT));
    setThemeLocalStorage(sTheme || CONSTANTS_THEMES.LIGHT);
  }

  const GetTranslation = (sKey, oOptions = null) => {
    if (!sKey)
      return null;
    let sReturn = sKey;
    switch (sLanguage) {
      case CONSTANTS_LANGUAGES.ENGLISH:
        sReturn = ENGLISH_TRANSLATE[sKey] || sKey;
        break;
      case CONSTANTS_LANGUAGES.PORTUGUESE:
        sReturn = PORTUGUESE_TRANSLATE[sKey] || sKey;
        break;
      case CONSTANTS_LANGUAGES.SPANISH:
      default:
        sReturn = SPANISH_TRANSLATE[sKey] || sKey;
        break;
    }
    if (!oOptions)
      return sReturn;
    for (const sOptionKey in oOptions) {
      if (Object.hasOwnProperty.call(oOptions, sOptionKey))
        sReturn = sReturn.replace(`{{${sOptionKey}}}`, oOptions[sOptionKey]);
    }
    return sReturn;
  };

  const GetDateFormatByLanguage = (bIsTimestamps = false) => {
    if (sLanguage === CONSTANTS_LANGUAGES.ENGLISH)
      return bIsTimestamps ? DATES_FORMATS.TIMESTAMPS_EN : DATES_FORMATS.EN;
    return bIsTimestamps ? DATES_FORMATS.TIMESTAMPS_LATIN : DATES_FORMATS.LATIN;
  }

  return <SharedContext.Provider
    value={{
      IsAuthenticated,
      bDarkTheme,
      GetTranslation,
      GetLanguageStorage,
      GetThemeStorage,
      ChangeTheme,
      GetDateFormatByLanguage,
    }}>
    {children}
  </SharedContext.Provider>;
}


import { createStore, combineReducers, applyMiddleware } from "redux";
import oThunk from "redux-thunk";

import { SharedReducers } from "redux/reducers/SharedReducers";
import { SessionReducers } from "redux/reducers/SessionReducers";
import { BarrelReducers } from "redux/reducers/BarrelReducers";
import { FermenterReducers } from "redux/reducers/FermenterReducers";
import { BeerReducers } from "redux/reducers/BeerReducers";
import { ClientReducers } from "redux/reducers/ClientReducers";
import InvoiceReducers from "redux/reducers/InvoiceReducers";
import ConfigReducers from "redux/reducers/ConfigReducers";
import UserReducers from "redux/reducers/UserReducers";
import LogReducers from "redux/reducers/LogReducers";
import PublicReducers from "./reducers/PublicReducers";

const aReducers = combineReducers({
  SharedReducers,
  SessionReducers,
  BarrelReducers,
  FermenterReducers,
  BeerReducers,
  ClientReducers,
  InvoiceReducers,
  UserReducers,
  LogReducers,
  ConfigReducers,
  PublicReducers,
});

const oStore = createStore(aReducers, applyMiddleware(oThunk));

export default oStore;
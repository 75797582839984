import { USER_ACTIONS } from "constants/actions/User";
import { CONSTANTS_MESSAGE } from "constants/Message";
import { setEmptyTokenLocalStorage, setTokenLocalStorage } from "redux/services/StorageServices";
import { CheckCredentials, Delete, DeleteAccount, Find, Get, GetHomeService, Invite, LoginService, LogoutService, Update, UpdateProfile, UploadCompanyImage } from "redux/services/UserServices";
import { actionDismissLoading, actionDispatch, actionManageMessage, actionMessage, actionShowLoading } from "./SharedActions";

/**
 * Funcion de login, recibe un email, una contraseña y valida los datos contra el API y
 *  contra la autenticacion de Firebase.
 * 
 * @param {string} sEmail Email del usuario que desea ingresar
 * @param {string} sPassword Contraseña del usuario que desea ingresar
 * 
 * @author Leandro Curbelo
 */
export const actionLogin = (sEmail, sPassword) => {
  return dispatch => {
    dispatch(actionShowLoading());
    LoginService(sEmail, sPassword).then(async oSuccess => {
      await setTokenLocalStorage(oSuccess.data.remember_token);
      dispatch(actionDispatch(USER_ACTIONS.LOGIN_SUCCESS, oSuccess.data));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS, "WELCOME"));
    }).catch(oError => {
      dispatch(actionManageMessage(oError));
    });
  }
}

export const actionUpdateProfile = (oData) => {
  return dispatch => {
    dispatch(actionShowLoading());
    UpdateProfile(oData).then(oSuccess => {
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(USER_ACTIONS.LOGIN_SUCCESS, oSuccess?.data));
      dispatch(actionDismissLoading());
    }).catch(() => {
      dispatch(actionDismissLoading());
    });
  }
}

export const actionUploadCompanyImage = (oData) => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await UploadCompanyImage(oData);
      dispatch(actionDispatch(USER_ACTIONS.LOGIN_SUCCESS, oSuccess?.data));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    } catch (oError) {
      dispatch(actionManageMessage(oError));
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionCheckCredentials = (sToken) => {
  return dispatch => {
    dispatch(actionShowLoading());
    CheckCredentials(sToken).then(async oSuccess => {
      await setTokenLocalStorage(oSuccess?.data?.remember_token);
      dispatch(actionDispatch(USER_ACTIONS.LOGIN_SUCCESS, oSuccess?.data));
      dispatch(actionDismissLoading());
    }).catch(() => {
      dispatch(actionDismissLoading());
      setEmptyTokenLocalStorage();
    });
  }
}

export const actionDeleteAccount = () => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await DeleteAccount();
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionLogout());
    } catch (oError) {
      dispatch(actionManageMessage(oError));
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionLogout = () => {
  return dispatch => {
    dispatch(actionShowLoading());
    LogoutService().finally(() => {
      dispatch(actionDispatch(USER_ACTIONS.LOGOUT));
      dispatch(actionDismissLoading());
    });
  }
}

export const actionGetHomeData = () => {
  return dispatch => {
    dispatch(actionShowLoading());
    GetHomeService().then(oSuccess => {
      dispatch(actionDispatch(USER_ACTIONS.GET_HOME, oSuccess?.data));
    }).catch(oError => {
      dispatch(actionDispatch(USER_ACTIONS.GET_HOME, null));
    }).finally(() => dispatch(actionDismissLoading()));
  }
}

export const actionGetUsers = () => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await Get();
      dispatch(actionDispatch(USER_ACTIONS.GET, oSuccess?.data));
    } catch (oError) {
      dispatch(actionManageMessage(oError));
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionFindUser = (nId) => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await Find(nId);
      dispatch(actionDispatch(USER_ACTIONS.FIND, oSuccess?.data));
    } catch (oError) {
      dispatch(actionManageMessage(oError));
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionUpdateUser = (nId, oData) => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await Update(nId, oData);
      dispatch(actionGetUsers());
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    } catch (oError) {
      dispatch(actionManageMessage(oError))
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionDeleteUser = (nId) => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await Delete(nId);
      dispatch(actionGetUsers());
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(USER_ACTIONS.GO_BACK));
    } catch (oError) {
      dispatch(actionManageMessage(oError))
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionInviteUser = (oData) => {
  return async dispatch => {
    dispatch(actionShowLoading());
    try {
      const oSuccess = await Invite(oData);
      dispatch(actionGetUsers());
      dispatch(actionDispatch(USER_ACTIONS.SUCCESS));
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
    } catch (oError) {
      dispatch(actionManageMessage(oError));
    } finally {
      dispatch(actionDismissLoading());
    }
  }
}

export const actionCleanUser = () => {
  return async dispatch => {
    dispatch(actionDispatch(USER_ACTIONS.CLEAN));
  }
}

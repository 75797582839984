import { CLIENT_ACTIONS } from "constants/actions/Client";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  aClients: null,
  oPagination: null,
  oClient: null,
  oInvoices: null,

  bSuccess: false,
  bGoBack: false,
};

export const ClientReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case CLIENT_ACTIONS.GET:
      return { ...oState, aClients: oAction.data.data, oPagination: oAction.data.pagination };
    case CLIENT_ACTIONS.FIND:
      return { ...oState, oClient: oAction.data };
    case CLIENT_ACTIONS.GO_BACK:
      return { ...oState, bGoBack: true };
    case CLIENT_ACTIONS.SUCCESS:
      return { ...oState, bSuccess: true };
    case CLIENT_ACTIONS.CLEAN:
      return { ...oState, oClient: null, bSuccess: false, bGoBack: false, oInvoices: null };
    case CLIENT_ACTIONS.INVOICES:
      return { ...oState, oInvoices: oAction.data };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}
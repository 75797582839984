export const STATUS_BARREL = {
    NEW: 0,
    EMPTY_IN_FACTORY: 1,
    FULL_IN_FACTORY: 2,
    FULL_IN_CLIENT: 3,
    FINALIZED: 4,
    // This status only view into logs, when user refund barrels
    REFUND: 5,
};

export const STATUS_BARREL_LABEL = {
    NEW: "NEW",
    EMPTY_IN_FACTORY: "EMPTY_IN_FACTORY",
    FULL_IN_FACTORY: "FULL_IN_FACTORY",
    FULL_IN_CLIENT: "IN_CLIENT",
};

export const STATUS_BARREL_CLASS = {
    NEW: "bg-green-500 dark:bg-green-800 text-white dark:text-gray-300 w-min px-1 py-1 md:px-3 rounded-full text-xs",
    EMPTY_IN_FACTORY: "bg-red-500 dark:bg-red-800 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    FULL_IN_FACTORY: "bg-yellow-300 dark:bg-yellow-800 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    FULL_IN_CLIENT: "bg-blue-500 dark:bg-blue-800 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    REFUND: "bg-purple-500 dark:bg-purple-800 text-white dark:text-gray-300 w-min px-1 py-1 md:px-3 rounded-full text-xs",
};

export const STATUS_BARREL_CLASS_DISABLED = {
    NEW: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min px-1 py-1 md:px-3 rounded-full text-xs",
    EMPTY_IN_FACTORY: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    FULL_IN_FACTORY: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    FULL_IN_CLIENT: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
    REFUND: "bg-gray-400 dark:bg-gray-600 text-white dark:text-gray-300 w-min whitespace-nowrap px-1 py-1 md:px-3 rounded-full text-xs",
};

import axios from "axios";
import promise from "promise";
import store from "redux/Store";

import { actionLogout } from "redux/actions/UserActions";
import { getLanguageLocalStorage, getTokenLocalStorage } from "redux/services/StorageServices";
import { CONSTANTS_LANGUAGES } from "constants/Languages";

var oAxiosInstance = axios.create();

oAxiosInstance.interceptors.request.use(async function (oConfig) {
  let sToken = await getTokenLocalStorage();
  if (sToken && oConfig.method !== "OPTIONS")
    oConfig.headers.Authorization = sToken;
  var sLanguage = await getLanguageLocalStorage();
  oConfig.headers.language = sLanguage ? sLanguage : CONSTANTS_LANGUAGES.SPANISH;
  return oConfig;
}, function (oError) {
  return promise.reject(oError);
});

oAxiosInstance.interceptors.response.use((oResponse) => {
  return oResponse.data;
}, oError => {
  if (oError?.response?.status === 401)
    store.dispatch(actionLogout());
  if (oError?.response?.data?.message)
    return promise.reject(oError?.response?.data?.message);
  return promise.reject(oError.message);
});

export default oAxiosInstance;
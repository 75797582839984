import React, { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import MenuComponent from "components/menu/MenuComponent";
import ToastComponent from "components/shared/ToastComponent";
import LoadingComponent from "components/loading/LoadingComponent";

import { SharedContext } from "context/SharedContext";
import { actionCheckCredentials } from "redux/actions/UserActions";
import { getTokenLocalStorage } from "redux/services/StorageServices";
import { RouteConfig } from "routes/Router";
import { GetLoading } from "redux/selectors/SharedSelector";

export default function Layout() {

  const oDispatch = useDispatch();
  const { bDarkTheme, IsAuthenticated } = useContext(SharedContext);
  const [bIsOpen, setOpen] = useState(false);
  const bIsAuthenticate = useMemo(() => IsAuthenticated(), [IsAuthenticated]);

  const bLoading = useSelector(GetLoading);

  useEffect(() => {
    onCheckSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckSession = async () => {
    const sToken = await getTokenLocalStorage();
    if (sToken)
      oDispatch(actionCheckCredentials(sToken));
  }

  return (
    <main className={bDarkTheme ? "dark" : ""}>
      <Router>
        <Suspense fallback={<LoadingComponent open />}>
          <div className="flex bg-gray-50 dark:bg-dark">
            {
              bIsAuthenticate &&
              <MenuComponent open={bIsOpen} onChange={setOpen} />
            }
            <div className={"w-screen transition-all duration-300 pl-14 " + (bIsAuthenticate ? bIsOpen ? "md:pl-64" : "pl-14" : "pl-0")}>
              {/* // TODO: Loading with logo animation or similarly */}
              <RouteConfig />
            </div>
          </div>
        </Suspense>
        <LoadingComponent open={bLoading} />
        <ToastComponent />
      </Router>
    </main>
  );
}

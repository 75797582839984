const APP_SESSION_STORAGE = "@BrewControl:Session";
const APP_TOKEN_STORAGE = "@BrewControl:Token";
const APP_LANGUAGE_STORAGE = "@BrewControl:Language";
const APP_THEME_STORAGE = "@BrewControl:Theme";

export const setLoginDataLocalStorage = async (data) => {
    try {
        await localStorage.removeItem(APP_SESSION_STORAGE);
        await localStorage.setItem(APP_SESSION_STORAGE, JSON.stringify(data));
    } catch (error) {
        throw error;
    }
}

export const setEmptyLocalStorage = async () => {
    await localStorage.removeItem(APP_SESSION_STORAGE);
}

export const getLoginDataLocalStorage = async () => {
    return await localStorage.getItem(APP_SESSION_STORAGE);
}
/**
 * Funcion que almacena en el local storage el token de autorizacion para tomar los datos del API.
 * 
 * @param {string} sToken Token de autenticacion para los servicios
 * 
 * @author Leandro Curbelo
 */
export const setTokenLocalStorage = async (sToken) => {
    try {
        await localStorage.removeItem(APP_TOKEN_STORAGE);
        await localStorage.setItem(APP_TOKEN_STORAGE, sToken);
    } catch (oError) {
        throw oError;
    }
}
/**
 * Funcion que limpia el valor del token de autorizacion del local storage.
 * 
 * @author Leandro Curbelo
 */
export const setEmptyTokenLocalStorage = async () => {
    await localStorage.removeItem(APP_TOKEN_STORAGE);
}
/**
 * Funcion que toma del local storage el token de autorizacion.
 * 
 * @author Leandro Curbelo
 */
export const getTokenLocalStorage = async () => {
    return await localStorage.getItem(APP_TOKEN_STORAGE);
}
/**
 * Funcion que guarda el idioma establecido para el usuario en el local storage.
 * 
 * @author Leandro Curbelo
 */
export const setLanguageLocalStorage = async (sLanguage) => {
    await localStorage.removeItem(APP_LANGUAGE_STORAGE);
    await localStorage.setItem(APP_LANGUAGE_STORAGE, sLanguage);
}
/**
 * Funcion que toma el idioma establecido para el usuario desde el local storage.
 * 
 * @author Leandro Curbelo
 */
export const getLanguageLocalStorage = async () => {
    return await localStorage.getItem(APP_LANGUAGE_STORAGE);
}
/**
 * Funcion que guarda el idioma establecido para el usuario en el local storage.
 * 
 * @author Leandro Curbelo
 */
export const setThemeLocalStorage = async (sTheme) => {
    await localStorage.removeItem(APP_THEME_STORAGE);
    await localStorage.setItem(APP_THEME_STORAGE, sTheme);
}
/**
 * Funcion que toma el tema establecido por el usuario desde el local storage.
 * 
 * @author Leandro Curbelo
 */
export const getThemeLocalStorage = async () => {
    return await localStorage.getItem(APP_THEME_STORAGE);
}
import { FERMENTER_ACTIONS } from "constants/actions/Fermenters";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  aFermenters: null,
  aConnectors: null,
  aLiters: null,
  aCodes: null,
  aLots: null,

  oFermenter: null,

  bSuccess: false,
  bError: false,
};

export const FermenterReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case FERMENTER_ACTIONS.CREATE:
      return { ...oState, aCodes: oAction.data };
    case FERMENTER_ACTIONS.GET:
      return { ...oState, aFermenters: oAction.data };
    case FERMENTER_ACTIONS.FIND:
      return { ...oState, oFermenter: oAction.data };
    case FERMENTER_ACTIONS.GET_LOTS:
      return { ...oState, aLots: oAction.data };
    case FERMENTER_ACTIONS.GET_CONNECTORS:
      return { ...oState, aConnectors: oAction.data };
    case FERMENTER_ACTIONS.GET_LITERS:
      return { ...oState, aLiters: oAction.data };
    case FERMENTER_ACTIONS.SUCCESS:
      return { ...oState, bError: true };
    case FERMENTER_ACTIONS.ERROR:
      return { ...oState, bError: true };
    case FERMENTER_ACTIONS.CLEAN:
      return { ...oState, bSuccess: false, oFermenter: null, aCodes: null, aLots: null, bError: false, };
    case FERMENTER_ACTIONS.CLEAN_FERMENTER:
      return { ...oState, oFermenter: null };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}
import { BEER_ACTIONS } from "constants/actions/Beer";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
    aBeers: null,
    aStyles: null,

    oBeer: null,

    bSuccess: false,
    bGoBack: false,
};

export const BeerReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case BEER_ACTIONS.GET:
            return { ...oState, aBeers: oAction.data };
        case BEER_ACTIONS.GET_STYLES:
            return { ...oState, aStyles: oAction.data };
        case BEER_ACTIONS.FIND:
            return { ...oState, oBeer: oAction.data };
        case BEER_ACTIONS.GO_BACK:
            return { ...oState, bGoBack: true };
        case BEER_ACTIONS.SUCCESS:
            return { ...oState, bSuccess: true };
        case BEER_ACTIONS.CLEAN:
            return { ...oState, oBeer: null, bSuccess: false, bGoBack: false };
        case USER_ACTIONS.LOGOUT:
            return oInitialState;
        default:
            return oState;
    }
}
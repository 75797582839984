exports.ENGLISH_TRANSLATE = {
  EMAIL: "Email",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm password",
  COMPANY: "Company",
  COMPANY_NAME_REQUIRED: "Company name required",
  COMPANY_PHONE_REQUIRED: "Company phone required",
  COUNTRY: "Country",
  REGISTER: "Register",
  ENTER: "Enter",
  ACCEPT: "Accept",
  INVITE: "Invite",
  CANCEL: "Cancel",
  DELETE: "Delete",
  UPDATE: "Edit",
  ATTENTION: "Attention",
  CLIENTS: "Clients",
  WELCOME: "Welcome",
  BARREL: "Barrel",
  BARRELS: "Barrels",
  FERMENTERS: "Fermenters",
  FERMENTER: "Fermenter",
  IN_FACTORY: "In Factory",
  IN_CLIENT: "In Client",
  NEW: "New",
  REFUND: "Refund",
  STATUS: "Status",
  SCAN: "Scan",
  LIST: "List",
  HELLO: "Hello",
  DATE: "Date",
  LOT: "Lot",
  LOTS: "Lots",
  NOTHING_LOTS: "No lots",
  LOT_DATE: "Lot Date",
  FULL: "Full",
  EMPTY: "Empty",
  NOTHING_BARRELS_CLIENTS: "At the moment all the barrels are at home 😌",
  NOTHING_BARRELS_FACTORY: "We do not have barrels in the deposit",
  NO_BARRELS: "No barrels entered",
  TOTAL: "Total",
  PAYMENT: "Payment",
  ADD_PAYMENT: "Add payment",
  YES: "Yes",
  NO: "No",
  PARTIAL: "Partial",
  NOTHING_FERMENTERS_FULL: "No full fermenters, time to cook 👨🏼‍🍳👩🏼‍🍳",
  NOTHING_FERMENTERS_EMPTY: "All fermenters are full",
  NO_FERMENTERS: "No fermenters entered",
  MY_PROFILE: "My Profile",
  CHANGE_PHOTO: "Change photo",
  NAME: "Name",
  PHONE: "Phone",
  ADDRESS: "Address",
  SAVE: "Save",
  SUCCESS: "Success",
  DELIVERED: "Delivered",
  RETRY: "Retry",
  DATA_SCANNER: "Data scanned",
  SCANNED_BARRELS: "Scanned barrels",
  SCANNED_FERMENTERS: "Scanned fermenters",
  EMPTY_SCANNED: "No scanned data",
  EMPTY_SCANNED_MESSAGE: "The scanned data will appear here",
  EMPTY_LAST_INVOICES: "No recent invoices",
  FULL_IN_FACTORY: "Full in factory",
  EMPTY_IN_FACTORY: "Empty in factory",
  CONFIRMATION: "Confirmation",
  NO_DATA_SCANNED: "No data is scanned yet",
  CONTENT: "Content",
  CONNECTOR: "Connector",
  CONNECTORS: "Connectors",
  SELECT_GROUP_FEATURES: "Select group features",
  SELECT_BARREL_FEATURES: "Select barrel features",
  SELECT: "Select",
  LITERS: "Liters",
  YEAST: "Yeast",
  YEAST_TYPE: "Yest type",
  YEAST_AMOUNT: "Yeast amount",
  YEAST_GENERATION: "Yeast generation",
  GENERATION: "Generation",
  IDENTIFIER: "Identifier",
  OPTIONAL: "Optional",
  NO_LITERS_SELECTED: "Select number of liters",
  NO_CONNECTOR_SELECTED: "Select a connector",
  NO_LOT_SELECTED: "Select a lot",
  NO_DATE_SELECTED: "Select a date",
  NO_BEER_SELECTED: "Select a style of beer",
  NO_CLIENT_SELECTED: "Select a client",
  LOT_SELECTION: "Lot selection",
  CLOSE_LOT: "Close lot",
  INFORMATION: "Information",
  LOT_DETAILS: "Lot details",
  WHEN_FILLING: "Liters when filled",
  LITERS_REPORTED: "Reported liters",
  NO_ASSOCIATED_BARRELS: "No associated barrels",
  NO_LOT_ASSOCIATED: "No associated lot",
  NO_BEER_ASSOCIATED: "No associated beer",
  FINAL_PRICE: "Final price",
  SELECT_DATE_OR_SCAN_FERMENTER: "Select a date or scan a fermenter",
  SELECT_DATE_AND_BEER: "Select the date and type of beer",
  SCAN_FERMENTER: "Scan Fermenter",
  SCAN_DATA_TO_PROCESS: "Scan a data to continue",
  CLEAN: "Clean",
  BEER: "Beer",
  CLIENT: "Client",
  RETURN_DATE: "Return date",
  RETURN: "Return",
  NEXT_RETURN: "Next return",
  ESTIMATED_DATE_RETURN: "Estimated date for return",
  NOTHING_CLIENTS: "No registered clients",
  NOTHING_BEERS: "No registered beers",
  NOTHING_FERMENTERS: "No registered fermenters",
  NOTHING_BARRELS: "No registered barrels",
  NOTHING_INVOICES: "No invoices",
  EMPTY_ADDRESS: "No assigned address",
  EMPTY_EMAIL: "No associated email",
  EMPTY_PHONE: "No associated phone",
  EMPTY_DESCRIPTION: "No associated description",
  EMPTY_PRICE: "No associated price",
  EMPTY_DATA_SHORT: "N/A",
  WITHOUT_YEAST: "Without associated yeast",
  CLIENT_DETAILS: "Client detail",
  CLIENT_DATA: "Client data",
  BEER_DETAILS: "Beer detail",
  INVOICE_DETAILS: "Invoice detail",
  USER_DETAILS: "User details",
  SEARCH: "Search",
  FILTER: "Filter",
  CLOSE: "Close",
  NO_BARRELS_ASOCIATED: "No associated barrels",
  NEW_CLIENT: "New client",
  NEW_BEER: "New beer",
  UPLOAD_PHOTO: "Upload photo",
  UPLOAD_IMAGE: "Upload image",
  PRICE: "Price",
  PRICE_PER_LITER: "Price per liter",
  DESCRIPTION: "Description",
  STYLE: "Style",
  INVOICE: "Invoice",
  INVOICES: "Invoices",
  INVOICES_OF: "Invoices of",
  INVOICES_IN_LAST_MONTHS: "Invoices in the last few months",
  AMOUNT: "Amount",
  PAID_OUT: "Paid out",
  PAY: "Pay",
  PENDING_PAYMENT: "Pending payment",
  PARTIALLY_PAID: "Partially paid",
  PENDING_ACTION: "Pending action",
  WITHOUT_INVOICES: "Without invoices",
  DEBT: "Debt",
  WITHOUT_DEBT: "Without debt",
  ACCOUNT_STATUS: "Account status",
  VIEW_INVOICES: "View invoices",
  SING_UP_LABEL: "Do not you have an account yet? Sign up",
  ACCEPT_TERM_AND_CONDITIONS: "By signing up, you agree to our Terms of Service and Privacy Policy.",
  PASSWORD_DO_NOT_MATCH: "Passwords do not match",
  PASSWORD_REQUIREMENTS: "Your password must contain at least 6 characters with at least one lowercase, one uppercase and one number",
  CHECK_MAILBOX: "Please check your mailbox and verify your account in order to access BrewControl",
  EMPTY_DATA_FILTERED: "No data for that filter",
  VIEW_USERS: "View users",
  CONFIG: "Configuration",
  NOTHING_USERS: "No users",
  USER: "User",
  USERS: "Users",
  LOGS: "Records",
  NOTHING_LOGS: "No records",
  LAST_CONNECTION: "Last connection",
  INVITE_USER: "Invite user",
  BARRELS_SELECTED: "Selected barrels",
  IS_IN: "Is in",
  CONTACT_INFORMATION: "Contact information",
  CODE: "Code",
  FILLING_DATE: "Filling date",
  BARREL_INFORMATION: "Barrel information",
  SCAN_QR_CODE: "Scan the barrel's QR code",
  BARREL_NOT_FOUND: "Barrel not found",
  RE_SCAN: "Re-scan",

  SEND_LEAVE_BARRELS: "Email when leaving barrels",
  SEND_LIFT_BARRELS: "Email when lifting barrels",
  SEND_REFUND_BARRELS: "Email when returning barrels",
  TO_YOU: "To your company",
  AN_ERROR_OCURRED_UPDATING_CONFIG: "An error occurred updating the configuration",

  BARREL_FILLING: "Barrel filling",
  NUMBER_MALFORMED: "Number malformed",
  DRAG_HERE: "Drag here",
  DROP_HERE: "Drop here",
  DROP_FILE_ERROR_MESSAGE: "Error while uploading file",
  ACTION_CANNOT_BE_UNDONE: "This action cannot be undone",

  CONFIRM_BARRELS: "Do you confirm the exchange rate of {{count}} barrel/s?",
  CONFIRM_NEW_BARRELS: "Do you confirm the incorporation of {{count}} barrel(s) new?",
  CONFIRM_CHANGE_BARRELS: "Are you sure you want to change the status of these barrels?",

  CONFIRM_FERMENTERS: "Confirm fermenter state change?",
  CONFIRM_NEW_FERMENTERS: "Confirm the addition of the new fermenter?",
  CONFIRM_CHANGE_FERMENTERS: "Are you sure you want to change the status of this fermenter?",

  CONFIRM_DELETE_CLIENT: "Are you sure you want to remove the client?",

  CONFIRM_DELETE_BEER: "Are you sure you want to remove the beer?",

  UNABLE_DELETE_CLIENT: "Unable to delete a client with associated barrels",

  CONFIRM_DELETE_ACCOUNT: "Are you sure you want to remove your account?",

  CONFIRM_DELETE_INVOICE: "Are you sure you want to remove the invoice?",

  CONFIRM_DELETE_USER: "Are you sure you want to delete the user?",

  CONFIRM_DELETE_BARREL: "Are you sure you want to delete this barrel?",
  CONFIRM_DELETE_BARRELS: "Are you sure you want to delete these barrels?",

  HOME: "Home",
  BEERS: "Beers",
  LOG_OUT: "Log out",
  BACK: "Back",

  EMAIL_REQUIRED: "Email required",
  PASSWORD_REQUIRED: "Password required",
  NAME_REQUIRED: "Name required",
  STYLE_REQUIRED: "Style required",
  ALL_FIELDS_ARE_REQUIRED: "All fields are required",
  ACTION_REQUIRED: "Action required",
  ADD_BARRELS: "Add barrels",
  BARREL_COUNT: "Quantity of barrels",
  BARREL_COUNT_REQUIRED: "Quantity of barrels required",
  BARREL_COUNT_INVALID: "Quantity of barrels invalid",
  ADD_FERMENTERS: "Add fermenters",
  FERMENTER_COUNT: "Quantity of fermenters",
  FERMENTER_COUNT_REQUIRED: "Quantity of fermenters required",
  FERMENTER_COUNT_INVALID: "Quantity of fermenters invalid",
  UPDATE_FERMENTER_DETAILS: "Update fermenter data",
  LITERS_REQUIRED: "Liters required",
  LITERS_FORMAT_ERROR: "Liters is not correct",
  PRINT: "Print",
  PRINT_QRS: "Print QR codes",
  WANT_PRINT_CODES: "Do you want to print QR codes?",
  PAYMENT_REQUIRED: "Payment is required",
  OPTIONS: "Options",

  OPEN: "Open",
  FINALIZED: "Finalized",

  EMAIL_FORMAT_ERROR: "Incorrect email format",

  NETWORK_ERROR: "Network error",

  METHOD_NOT_IMPLEMENTED: "Method not implemented",

  DEFAULT_MESSAGE_ERROR: "An error has occurred, please try again later",
  DEFAULT_MESSAGE_INFO_TITLE: "Information",
  DEFAULT_MESSAGE_ERROR_TITLE: "We got a problem ❌",
  DEFAULT_MESSAGE_SUCCESS_TITLE: "Success ✅",

  THEME: "Theme",
  LIGHT: "Light",
  DARK: "Dark",
  SYSTEM: "System",

  LANGUAGE: "Language",
  PT: "Portuguese",
  ES: "Spanish",
  EN: "English",
  LOADING: "Loading",
};

import { BARREL_ACTIONS } from "constants/actions/Barrel";
import { USER_ACTIONS } from "constants/actions/User";

const oInitialState = {
  oBarrel: null,

  aCodes: null,

  aBarrels: null,
  oPagination: null,

  aConnectors: null,
  aLiters: null,

  bError: false,
  bSuccess: true,
  bGoBack: false,
};

export const BarrelReducers = (oState = oInitialState, oAction) => {
  switch (oAction.type) {
    case BARREL_ACTIONS.CREATE:
      return { ...oState, aCodes: oAction.data };
    case BARREL_ACTIONS.GET:
      return { ...oState, aBarrels: oAction.data.data, oPagination: oAction.data.pagination };
    case BARREL_ACTIONS.FIND:
      return { ...oState, oBarrel: oAction.data };
    case BARREL_ACTIONS.CLEAN_BARREL:
      return { ...oState, oBarrel: null };
    case BARREL_ACTIONS.GET_CONNECTORS:
      return { ...oState, aConnectors: oAction.data };
    case BARREL_ACTIONS.GET_LITERS:
      return { ...oState, aLiters: oAction.data };
    case BARREL_ACTIONS.SUCCESS:
      return { ...oState, bSuccess: true };
    case BARREL_ACTIONS.ERROR:
      return { ...oState, bError: true };
    case BARREL_ACTIONS.GO_BACK:
      return { ...oState, bGoBack: true };
    case BARREL_ACTIONS.CLEAN:
      return { ...oState, bError: false, oBarrel: null, aCodes: null, bGoBack: false, bSuccess: false };
    case USER_ACTIONS.LOGOUT:
      return oInitialState;
    default:
      return oState;
  }
}
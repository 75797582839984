export const CONSTANTS_LANGUAGES = {
    ENGLISH: "en",
    SPANISH: "es",
    PORTUGUESE: "pt",
}

export const CONSTANTS_LANGUAGES_ITEMS = [
    { label: "ES", value: "es" },
    { label: "EN", value: "en" },
    { label: "PT", value: "pt" },
];

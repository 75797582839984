import { useContext, useEffect } from "react";
import { toast, Zoom } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { GetMessage } from "redux/selectors/SharedSelector";
import { actionCleanMessage } from "redux/actions/SharedActions";
import { SharedContext } from "context/SharedContext";

const CONFIG_MESSAGE = {
    position: "bottom-right",
    autoClose: 5000,
    // icon: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const ToastComponent = () => {

    const oDispatch = useDispatch();
    const { GetTranslation, bDarkTheme } = useContext(SharedContext);

    const oMessage = useSelector(GetMessage);

    useEffect(() => {
        if (oMessage.message) {
            toast(GetTranslation(oMessage.message), {
                ...CONFIG_MESSAGE,
                type: oMessage.type,
                theme: bDarkTheme ? "dark" : "light",
                transition: Zoom
            });
            oDispatch(actionCleanMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oMessage.message]);

    return null;
};

export default ToastComponent;
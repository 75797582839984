import React, { useContext } from "react";

import { SharedContext } from "context/SharedContext";

export const CONSTANTS_TEXT_STYLE = {
    DEFAULT: 0,
    LIGHT: 1,
    DARK: 2,
};

const TextComponent = ({
    children: sText,
    size: sSize = "",
    className: sCustomClass = "",
    color: sColor = null,
    bold: bIsBold = false,
    italic: bIsItalic = false,
    style: nStyle = CONSTANTS_TEXT_STYLE.DEFAULT,
    simple: bSimple = false,
    options: oOptions = null,
}) => {

    const { GetTranslation } = useContext(SharedContext);

    if (bSimple)
        return <p className={sCustomClass}>
            {GetTranslation(sText, oOptions)}
        </p>;

    let aClassNames = [];

    switch (nStyle) {
        case CONSTANTS_TEXT_STYLE.LIGHT:
            aClassNames = ["text-white dark:text-gray-200",];
            break;
        case CONSTANTS_TEXT_STYLE.DARK:
            aClassNames = ["text-gray-800 dark:text-gray-400",];
            break;
        case CONSTANTS_TEXT_STYLE.DEFAULT:
        default:
            aClassNames = ["text-gray-500 dark:text-gray-300",];
            break;
    }

    if (sColor)
        aClassNames[0] = sColor;
    if (sSize)
        aClassNames.push(sSize);
    if (bIsItalic)
        aClassNames.push("italic");
    if (bIsBold)
        aClassNames.push("font-bold");
    if (sCustomClass)
        aClassNames.push(sCustomClass);

    return <p className={aClassNames.join(" ")}>
        {GetTranslation(sText, oOptions)}
    </p>;
}

export default TextComponent;
